import request from '@/plugins/request'

const prefix = 'system'

/**
 * @return {AxiosPromise}
 */
export const getSystemSettings = () => {
    const options = {
        method: 'GET',
        url: `${prefix}`
    }

    return request(options)
}

/**
 * @return {AxiosPromise}
 */
export const getSystemSettingsKey = (key) => {
    const options = {
        method: 'GET',
        url: `${prefix}/${key}`
    }

    return request(options)
}

/**
 * @return {AxiosPromise}
 */
export const setSystemSettings = (data) => {
    const options = {
        method: 'POST',
        url: `${prefix}`,
        data: data
    }

    return request(options)
}

/**
 * @return {AxiosPromise}
 */
export const getLocalisation = (locale) => {
    const options = {
        method: 'GET',
        url: `${prefix}/localisation`,
        params: { locale }
    }

    return request(options)
}

/**
 * @return {AxiosPromise}
 */
export const getLanguages = () => {
    const options = {
        method: 'GET',
        url: `${prefix}/languages`
    }

    return request(options)
}
