import dashboardThemeConfig from '@themeConfig'

require('./overrides.scss')

// Skins
require('./skins/bordered.scss')
require('./skins/semi-dark.scss')

export default {
    theme: {
        themes: {
            ...dashboardThemeConfig.themes.clickup
        },
        dark: localStorage.getItem('materio-active-theme') === null
            ? dashboardThemeConfig.app.isDark
            : localStorage.getItem('materio-active-theme') === 'dark'
    },
    rtl: dashboardThemeConfig.app.isRtl
}
