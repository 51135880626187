export default {
    props: {
        title: {
            type: String,
            default: null
        },
        subtitle: {
            type: String,
            default: null
        },
        saveText: {
            type: String,
            default: 'Save'
        },
        updateText: {
            type: String,
            default: 'Update'
        },
        deleteText: {
            type: String,
            default: 'Delete'
        },
        item: {
            type: [ Object, Function ],
            default: function () {
                return {}
            }
        },
        fields: {
            type: Array,
            default: function () {
                return []
            }
        },
        errors: {
            type: Object,
            default: function () {
                return {}
            }
        },
        editable: {
            type: Boolean,
            default: false
        },
        deletable: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        hideDefaultActions: {
            type: Boolean,
            default: false
        },
        observer: {
            type: Object,
            default: null
        },
        throughPreview: {
            type: Boolean,
            default: false
        }
    }
}
