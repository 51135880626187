/* eslint-disable */
import {
    COURSE,
    DEPARTMENT, DOCUMENTS,
    GROUP,
    LEVEL, QUESTIONS,
    SCHEDULE,
    STUDENT,
    TEACHER
} from '@/widgets/components/WIcon/icons'
import { ROLE_ADMIN, ROLE_CURATOR, ROLE_MANAGER, ROLE_SUPER_ADMIN } from '@apps/auth/config/roles'
import { MEMBER_TEACHER } from '@apps/auth/config/members'

export default [
    {
        title: null,
        subtitle: null,
        hint: null,
        name: 'school',
        access: {
            roles: [ ROLE_SUPER_ADMIN, ROLE_ADMIN ]
        },
        items: [
            {
                icon: DEPARTMENT,
                title: 'Departments',
                subtitle: null,
                color: null,
                component: () => import('./views/Management/DepartmentsManage'),
                path: 'departments',
                name: 'departments'
            },
            {
                icon: COURSE,
                title: 'Courses',
                subtitle: null,
                color: null,
                component: () => import('./views/Management/Courses/CoursesManage'),
                path: 'courses',
                name: 'courses',
                children: [
                    {
                        path: ':uuid',
                        component: () => import('./views/Management/Courses/CourseDetailsManage'),
                        name: 'details',
                    }
                ]
            },
            {
                icon: TEACHER,
                title: 'Teachers',
                subtitle: null,
                color: null,
                component: () => import('./views/Management/Teachers/TeachersManage'),
                path: 'teachers',
                name: 'teachers',
                children: [
                    {
                        path: ':uuid',
                        component: () => import('./views/Management/Teachers/TeacherProfileManage'),
                        name: 'profile'
                    }
                ]
            }
        ]
    },
    {
        title: null,
        subtitle: null,
        hint: null,
        path: 'teacher',
        name: 'teacher',
        access: {
            members: [ MEMBER_TEACHER ]
        },
        items: [
            {
                icon: DOCUMENTS,
                title: 'Documents',
                subtitle: null,
                color: null,
                component: () => import('./views/Docs'),
                path: 'docs',
                name: 'docs'
            }
        ]
    },
    {
        title: null,
        subtitle: null,
        hint: null,
        path: 'teacher',
        name: 'teacher',
        access: {
            members: [ MEMBER_TEACHER ]
        },
        items: [
            {
                icon: QUESTIONS,
                title: 'Tests',
                subtitle: null,
                color: null,
                component: () => import('./views/Questions/QuestionsTeacherPage'),
                path: 'questions',
                name: 'questions',
                children: [
                    {
                        path: ':uuid/sets',
                        props: true,
                        component: () => import('./views/Questions/QuestionsSetsPage'),
                        name: 'sets'
                    }
                ]
            }
        ]
    },
    {
        title: null,
        subtitle: null,
        path: 'curator',
        name: 'curator',
        access: {
            roles: [ ROLE_CURATOR ]
        },
        items: [
            {
                icon: STUDENT,
                title: 'Students',
                subtitle: null,
                color: null,
                component: () => import('./views/Management/Students/StudentsCuratorManage'),
                path: 'students',
                name: 'students'
            }
        ]
    },
    {
        title: null,
        subtitle: null,
        name: 'school',
        access: {
            roles: [ ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_MANAGER ]
        },
        items: [
            {
                icon: LEVEL,
                title: 'Levels',
                subtitle: null,
                color: null,
                component: () => import('./views/Management/LevelsManage'),
                path: 'levels',
                name: 'levels',
                access: {
                    roles: [ ROLE_SUPER_ADMIN, ROLE_ADMIN ]
                },
            },
            {
                icon: GROUP,
                title: 'Groups',
                subtitle: null,
                color: null,
                component: () => import('./views/Management/GroupsManage'),
                path: 'groups',
                name: 'groups',
                access: {
                    roles: [ ROLE_SUPER_ADMIN, ROLE_ADMIN ]
                },
            },
            {
                icon: STUDENT,
                title: 'Students',
                subtitle: null,
                color: null,
                component: () => import('./views/Management/Students/StudentsManage'),
                path: 'students',
                name: 'students',
                access: {
                    roles: [ ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_MANAGER ]
                },
            }
        ]
    },
    {
        title: null,
        subtitle: null,
        hint: null,
        name: 'school',
        access: {
            roles: [ ROLE_SUPER_ADMIN, ROLE_MANAGER ]
        },
        items: [
            {
                icon: SCHEDULE,
                title: 'Schedule',
                subtitle: null,
                color: null,
                component: () => import('./views/Management/Schedule/ScheduleLevels'),
                path: 'schedule',
                name: 'schedule',
                children: [
                    {
                        path: ':uuid',
                        component: () => import('./views/Management/Schedule/ScheduleLevel'),
                        name: 'level'
                    }
                ]
            }
        ]
    }
]
