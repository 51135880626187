import Vue from 'vue'

const state = {
    access: null
}

const mutations = {
    SET_ACCESS(state, data) {
        Vue.set(state, 'access', data)
    }
}

const actions = {
    fetchAccess({ commit }, payload) {
        commit('SET_ACCESS', payload)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
