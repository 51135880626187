/* eslint-disable */
import _ from 'lodash'
import Vue from 'vue'
import { getLevelsCollection } from '@/apps/school/api/levels'
import { getDepartmentsCollection } from '@/apps/school/api/departments'
import { getSchoolsCollection } from '@apps/school/api/schools'
import { getSettings, getSettingsSet } from '@apps/settings/api'
import { getLessonTypesCollection } from '@apps/school/api/lessonTypes'
import { AuthProvider } from '@apps/auth/utils'
import { getStudyPeriods } from '@apps/school/api/studyPeriods'
import moment from 'moment'

const availableSchoolsResolver = (auth, items) => {
    if(auth.isAdmin() || auth.isManager()) {
        return items.filter(o => {
            return auth.hasPermission('school', o.uuid)
        })
    }

    return items
}

export default {
    namespaced: true,
    state: {
        currentSchool: null,
        schools: [],
        levels: [],
        departments: [],
        callSchedule: [],
        studyPeriods: [],
        lessonTypes: [],
        lessonTypeGroups: [],
        minAge: 6,
        maxAge: 50,
        scores: {
            min_score: 0,
            max_score: 0,
            statuses: []
        }
    },
    getters: {
        schools: state => state.schools,
        currentSchool: state => state.currentSchool,
        levels: state => state.levels,
        level: state => uuid => state.levels.find(level => level.uuid === uuid),
        group: state => uuid => {
            const level = _.find(state.levels, { groups: [ { uuid: uuid } ] })

            if (!level) {
                return null
            }

            return _.find(level.groups, [ 'uuid', uuid ])
        },
        departments: state => state.departments,
        department: state => uuid => state.departments.find(department => department.uuid === uuid),
        studyPeriods: state => state.studyPeriods,
        currentStudyPeriod: state => {
            const filtered = state.studyPeriods.filter(o => {
                return moment().isBetween(o.values.begin, o.values.end, undefined, '[]')
            })

            return filtered.length === 1 ? filtered[0] : null
        },
        lessonTypes: state => state.lessonTypes,
        lessonTypeGroups: state => state.lessonTypeGroups,
        callSchedule: state => state.callSchedule,
        scores: state => state.scores
    },
    mutations: {
        SET_SCHOOLS: (state, items) => {
            Vue.set(state, 'schools', items)
        },
        SET_CURRENT_SCHOOL: (state, item) => {
            Vue.set(state, 'currentSchool', item)
        },
        SET_LEVELS: (state, items) => {
            Vue.set(state, 'levels', items)
        },
        SET_DEPARTMENTS: (state, items) => {
            Vue.set(state, 'departments', items)
        },
        SET_CALL_SCHEDULE: (state, items) => {
            Vue.set(state, 'callSchedule', items)
        },
        SET_STUDY_PERIODS: (state, items) => {
            Vue.set(state, 'studyPeriods', items)
        },
        SET_LESSON_TYPES: (state, items) => {
            Vue.set(state, 'lessonTypes', items)
        },
        SET_LESSON_TYPE_GROUPS: (state, items) => {
            Vue.set(state, 'lessonTypeGroups', items)
        },
        SET_SCORES: (state, items) => {
            Vue.set(state, 'scores', items)
        }
    },
    actions: {
        async fetchSchools({ commit }) {
            await getSchoolsCollection()
                .then(response => {
                    const auth = new AuthProvider()
                    const items = availableSchoolsResolver(auth, response.data.data)

                    if(items.length === 0) {
                        throw new Error('Schools not defined')
                    }

                    commit('SET_SCHOOLS', items)
                    commit('SET_CURRENT_SCHOOL', items[0])
                })
        },
        async changeSchool({ state, dispatch, commit }, uuid) {
            await commit('SET_CURRENT_SCHOOL', state.schools.find(o => o.uuid === uuid))
            await dispatch('fetchLevels')
            await dispatch('fetchDepartments')
        },
        async fetchLevels({ commit, state }) {
            if(!state.currentSchool) {
                return
            }

            await getLevelsCollection({
                with: 'groups:uuid,level_uuid,title,description,weight',
                school: state.currentSchool.uuid
            })
              .then(response => {
                  commit('SET_LEVELS', response.data.data)
              })
        },
        async fetchDepartments({ commit, state }) {
            if(!state.currentSchool) {
                return
            }

            await getDepartmentsCollection({
                school: state.currentSchool.uuid
            })
              .then(response => {
                  commit('SET_DEPARTMENTS', response.data.data)
              })
        },
        async fetchCallSchedule({ commit }) {
            await getSettings('call-schedule')
                .then(response => {
                    commit('SET_CALL_SCHEDULE', response.data.data)
                })
        },
        async fetchStudyPeriods({ commit }) {
            await getStudyPeriods()
                .then(response => {
                    commit('SET_STUDY_PERIODS', response.data.data)
                })
        },
        async fetchLessonTypes({ commit }) {
            await getLessonTypesCollection()
                .then(response => {
                    commit('SET_LESSON_TYPES', response.data.data)
                    commit('SET_LESSON_TYPE_GROUPS', response.data.lesson_type_groups)
                })
        },
        async fetchScores({ commit }) {
            await getSettingsSet('scores')
                .then(response => {
                    commit('SET_SCORES', response.data.data)
                })
        }
    }
}
