import store from '@/store'
import { setVuetifyI18nLanguage } from '@/plugins/vuetify'

export default [
    {
        event: 'change:locale',
        listener: async (locale) => {
            await store.dispatch('system/fetchLocalisation', locale)

            setVuetifyI18nLanguage(locale)
        }
    },
    {
        event: 'guest:bootstrap',
        listener: async () => {
            try {
                const localisation = store.getters['system/localisation']

                if(Object.keys(localisation).length > 0) return

                const locale = localStorage.getItem('ec.locale')

                await store.dispatch('system/fetchLocalisation', locale)

                setVuetifyI18nLanguage(locale)
            } finally {
                // ...
            }
        }
    },

]
