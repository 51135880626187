/* eslint-disable */
import { MEMBER_TEACHER } from '@apps/auth/config/members'

export default [
    {
        path: '/capacity',
        name: 'capacity',
        component: () => import('@apps/school/views/Capacity'),
        meta: {
            layout: 'content',
            access: {
                members: [MEMBER_TEACHER]
            }
        }
    }
]
