// Register apps services
// https://webpack.js.org/guides/dependency-management/#requirecontext

const modulesFiles = require.context('@/apps', true, /bootstrap.js$/)

export default Object.freeze({
    boot: async (vm) => {
        const bootstraps = modulesFiles.keys().reduce((modules, path) => {
            const value = modulesFiles(path)
            modules.push(value.default)

            return modules
        }, [])

        try {
            for (let i = 0; i < bootstraps.length; i++) {
                await bootstraps[i](vm)
            }
        } catch (e) {
            console.log(e)
        }
    }
})
