import Vue from 'vue'
import Notification from '@/apps/notification/utils'

const NotificationPlugin = {
    install(Vue) {
        Vue.prototype.$notification = Notification
    }
}

Vue.use(NotificationPlugin)
