export const isObject = obj => typeof obj === 'object' && obj !== null

export const dateInPast = (firstDate, secondDate) => {
    if (firstDate.setHours(0, 0, 0, 0) <= secondDate.setHours(0, 0, 0, 0)) {
        return true
    }

    return false
}

export const isEmpty = value => {
    if (value === null || value === undefined || value === '') {
        return true
    }

    if (Array.isArray(value) && value.length === 0) {
        return true
    }

    return false
}

export const isToday = date => {
    const today = new Date()

    return (
        /* eslint-disable operator-linebreak */
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
        /* eslint-enable */
    )
}

// ——— Get Initial Text from name ——————— //

export const getInitialName = fullName =>
    // eslint-disable-next-line implicit-arrow-linebreak
    fullName
        .split(' ')
        .map(n => n[0])
        .join('')

// ——— Add Alpha To color ——————— //

export const addAlpha = (color, opacity) => {
    const opacityLocal = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255)

    return color + opacityLocal.toString(16).toUpperCase()
}

// ————————————————————————————————————
//* ——— Color Manipulations
// ————————————————————————————————————

// Thanks: https://stackoverflow.com/a/5624139/10796681
export const rgbToHex = (r, g, b) => {
    const componentToHex = c => {
        const hex = c.toString(16)

        return hex.length === 1 ? `0${hex}` : hex
    }

    return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`
}

// Thanks: https://stackoverflow.com/a/5624139/10796681
export const hexToRgb = hex => {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
    // eslint-disable-next-line no-param-reassign
    hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b)

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

    /* eslint-disable indent */
    return result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        }
        : null
    /* eslint-enable */
}

export const windowSize = {
    width: function () {
        return window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth
    },

    height: function () {
        return window.innerHeight ||
            document.documentElement.clientHeight ||
            document.body.clientHeight
    }
}

export const timer = (timer) => {
    if(!timer || !timer.minutes) return

    if(!timer.current) timer.current = 0

    timer.quarter = 1
    timer.timeout = false
    timer.time = timer.minutes * 60

    const quarterStep = timer.time / 4

    timer.timeDisplay = `${ timer.minutes.toString().padStart(2, '0') }:00`

    const go = () => {
        const min = timer.minutes.toString().padStart(2, '0')
        const sec = ((timer.time - timer.current) % 60).toString().padStart(2, '0')

        if(timer.current > 0 && timer.current < timer.time) {
            timer.timeDisplay = `${ min }:${ sec }`
        }

        if((timer.time - timer.current) % 60 === 0) {
            timer.minutes--
        }

        if(timer.current >= quarterStep) timer.quarter = 2
        if(timer.current >= quarterStep * 2) timer.quarter = 3
        if(timer.current >= quarterStep * 3) timer.quarter = 4

        if(timer.current < timer.time) {
            setTimeout(go, 1000)
            timer.current++
        } else {
            timer.timeDisplay = '00:00'
            timer.timeout = true
        }
    }

    go()
}
