export default [
    {
        event: 'media:init:resource',
        listener: async resource => {
            switch (resource.type) {
                case 'questions_set':
                    resource.to = { name: 'manage.teacher.questions.sets', params: {uuid: resource.questions_uuid} }
                    break
                case 'course':
                    resource.to = { name: 'courses.details', params: {uuid: resource.uuid} }
                    break
                default:
                    resource.to = ''
            }
        }
    }
]
