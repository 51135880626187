import { TRANSLATE } from '@/widgets/components/WIcon/icons'
import { ROLE_SUPER_ADMIN } from '@apps/auth/config/roles'

export default [
    {
        title: null,
        subtitle: null,
        hint: 'hint.system.language',
        name: 'system',
        access: {
            roles: [ ROLE_SUPER_ADMIN ]
        },
        items: [
            {

                icon: TRANSLATE,
                title: 'Language',
                subtitle: null,
                color: null,
                component: () => import('./views/SystemLanguage'),
                path: 'languages',
                name: 'languages'
            }
        ]
    }
]
