import Vue from 'vue'
import { AuthProvider } from '@apps/auth/utils'

const AuthPlugin = {
    install(Vue) {
        Vue.prototype.$auth = new AuthProvider()
    }
}

Vue.use(AuthPlugin)
