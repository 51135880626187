const getters = {
    theme: state => state.theme,
    locale: state => state.auth.settings.locale,
    activeTheme: state => state.auth.settings.activeTheme,
    firstDayOfWeek: state => state.system.firstDayOfWeek,
    languages: state => state.system.languages.filter(o => o.reserved || state.system.enabledLocales.includes(o.locale)),
    notify: state => state.notification.notify, // snackbar: state => state.notification.snackbar,
    auth: state => state.auth
}

export default getters
