import request from '@/plugins/request'

const prefix = 'questions'
const prefixManage = 'questions/manage'
const prefixSet = 'questions/set'
const prefixResults = 'questions/results'

export const getQuestionsSettings = () => {
    const options = {
        method: 'GET',
        url: `${ prefix }/settings`
    }

    return request(options)
}

export const getQuestionsCollection = data => {
    const options = {
        method: 'POST',
        url: `${ prefix }`,
        data: data
    }

    return request(options)
}

export const getQuestionsSets = (uuid, params) => {
    const options = {
        method: 'GET',
        url: `${ prefix }/${uuid}/sets`,
        params
    }

    return request(options)
}

export const createQuestions = data => {
    const options = {
        method: 'POST',
        url: `${ prefixManage }`,
        data: {
            action: 'createQuestions',
            ...data
        }
    }

    return request(options)
}

export const updateQuestions = data => {
    const options = {
        method: 'POST',
        url: `${ prefixManage }`,
        data: {
            action: 'updateQuestions',
            ...data
        }
    }

    return request(options)
}

export const updateSharedQuestions = data => {
    const options = {
        method: 'POST',
        url: `${ prefixManage }`,
        data: {
            action: 'updateSharedQuestions',
            ...data
        }
    }

    return request(options)
}

export const copyQuestions = data => {
    const options = {
        method: 'POST',
        url: `${ prefixManage }`,
        data: {
            action: 'copyQuestions',
            ...data
        }
    }

    return request(options)
}

export const shareQuestions = data => {
    const options = {
        method: 'POST',
        url: `${ prefixManage }`,
        data: {
            action: 'shareQuestions',
            shared: data
        }
    }

    return request(options)
}

export const detachSharedQuestions = data => {
    const options = {
        method: 'POST',
        url: `${ prefixManage }`,
        data: {
            action: 'detachSharedQuestions',
            ...data
        }
    }

    return request(options)
}

export const removeQuestions = data => {
    const options = {
        method: 'POST',
        url: `${ prefixManage }`,
        data: {
            action: 'removeQuestions',
            ...data
        }
    }

    return request(options)
}

export const restoreQuestions = data => {
    const options = {
        method: 'POST',
        url: `${ prefixManage }`,
        data: {
            action: 'restoreQuestions',
            ...data
        }
    }

    return request(options)
}

export const deleteQuestions = data => {
    const options = {
        method: 'POST',
        url: `${ prefixManage }`,
        data: {
            action: 'deleteQuestions',
            ...data
        }
    }

    return request(options)
}

export const cleanUpQuestions = data => {
    const options = {
        method: 'POST',
        url: `${ prefixManage }`,
        data: {
            action: 'cleanUpQuestions',
            ...data
        }
    }

    return request(options)
}

export const getQuestionsSet = uuid => {
    const options = {
        method: 'GET',
        url: `${ prefixSet }/${ uuid }`
    }

    return request(options)
}

export const createQuestionsSet = data => {
    const options = {
        method: 'POST',
        url: `${ prefixSet }`,
        data: data
    }

    return request(options)
}

export const updateQuestionsSet = (uuid, data) => {
    const options = {
        method: 'PATCH',
        url: `${ prefixSet }/${ uuid }`,
        data: data
    }

    return request(options)
}

export const resortQuestionsSets = data => {
    const options = {
        method: 'PATCH',
        url: `${ prefixSet }/resort`,
        data: data
    }

    return request(options)
}

export const deleteQuestionsSet = uuid => {
    const options = {
        method: 'DELETE',
        url: `${ prefixSet }/${ uuid }`
    }

    return request(options)
}

export const getQuestionsResults = params => {
    const options = {
        method: 'GET',
        url: `${ prefixResults }`,
        params: {
            mode: 'results',
            ...params
        }
    }

    return request(options)
}

export const getQuestionsResultsDetails = params => {
    const options = {
        method: 'GET',
        url: `${ prefixResults }`,
        params: {
            mode: 'details',
            ...params
        }
    }

    return request(options)
}

export const getQuestionsResultsHistory = params => {
    const options = {
        method: 'GET',
        url: `${ prefixResults }`,
        params: {
            mode: 'history',
            ...params
        }
    }

    return request(options)
}

export const getQuestionsStatistic = params => {
    const options = {
        method: 'GET',
        url: `${ prefixResults }`,
        params: {
            mode: 'statistic',
            ...params
        }
    }

    return request(options)
}

export const createQuestionsResults = data => {
    const options = {
        method: 'POST',
        url: `${ prefixResults }`,
        data
    }

    return request(options)
}

export const updateQuestionsResults = (uuid, data) => {
    const options = {
        method: 'PATCH',
        url: `${ prefixResults }/${ uuid }`,
        data
    }

    return request(options)
}
