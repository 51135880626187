const itemRouteResolver = (data, basePath, baseRouteName, baseBreadcrumbs, baseAccess) => {
    const routePath = `${basePath}/${data.path}`
    const routeName = `${baseRouteName}.${data.name}`
    const routeBreadcrumbs = [].concat(baseBreadcrumbs)
    const routeAccess = data.access || null
    const routePropsFlag = !!data.props

    if (data.title) {
        routeBreadcrumbs.push({ text: data.title, to: { name: routeName } })
    }

    return {
        path: routePath,
        name: routeName,
        component: data.component,
        props: routePropsFlag,
        meta: {
            layout: data.layout || 'content',
            breadcrumbs: routeBreadcrumbs,
            access: routeAccess || baseAccess
        }
    }
}

const itemChildrenRoutesResolver = (children, routersItems, itemPath, itemRouteName, itemBreadcrumbs, itemAccess) => {
    children.forEach(itemChild => {
        const childRoute = itemRouteResolver(itemChild, itemPath, itemRouteName, itemBreadcrumbs, itemAccess)

        routersItems.push(childRoute)

        if (itemChild.children) {
            itemChildrenRoutesResolver(
                itemChild.children,
                routersItems,
                childRoute.path,
                childRoute.name,
                childRoute.meta.breadcrumbs,
                childRoute.meta.access
            )
        }
    })
}

const settingsResolver = (target, label, context) => {
    const settingsItems = []
    const routersItems = []

    const resolvedSettings = context.keys()
        .reduce((settings, path) => {
            const value = context(path)
            const settingsGroups = value.default

            settingsGroups.forEach(group => {
                if(!group.path) {
                    group.path = path.replace('./', '').replace(`/${target}.js`, '')
                }

                settings.push(group)
            })

            return settings
        }, [])

    for (let i = 0; i < resolvedSettings.length; i++) {
        const group = resolvedSettings[i]

        if (group.component) {
            delete group.path

            settingsItems.push(group)
            continue
        }

        const groupPath = `/${target}/${group.path}`
        const groupRouteName = `${target}.${group.name || group.path}`
        const groupBreadcrumbs = [
            {
                text: label,
                to: { name: target }
            }
        ]

        const groupSettings = {
            items: []
        }

        groupSettings.title = group.title || null
        groupSettings.subtitle = group.subtitle || null
        groupSettings.hint = group.hint || null
        groupSettings.access = group.access || null

        for (let j = 0; j < group.items.length; j++) {
            const item = group.items[j]
            const itemRoute = itemRouteResolver(item, groupPath, groupRouteName, groupBreadcrumbs, groupSettings.access)

            routersItems.push(itemRoute)

            if (item.children) {
                itemChildrenRoutesResolver(item.children, routersItems, itemRoute.path, itemRoute.name, itemRoute.meta.breadcrumbs, itemRoute.meta.access)
            }

            groupSettings.items.push({
                title: item.title,
                subtitle: item.subtitle || null,
                icon: item.icon || null,
                color: item.color || 'primary',
                action: item.action || null,
                to: { name: itemRoute.name },
                access: item.access || null,
            })
        }

        settingsItems.push(groupSettings)
    }

    return {
        settings: settingsItems,
        routers: routersItems
    }
}

const manageSettings = require.context('@/apps', true, /manage.js$/)
const appsSettings = require.context('@/apps', true, /settings.js$/)

export default {
    settings: settingsResolver('settings', 'Settings', appsSettings),
    manage: settingsResolver('manage', 'Library', manageSettings)
}
