import dashboardThemeConfig from '@themeConfig'

export default {
    namespaced: true,
    state: {
        app: {
            contentLayoutNav: dashboardThemeConfig.app.contentLayoutNav,
            routeTransition: dashboardThemeConfig.app.routeTransition,
            skinVariant: localStorage.getItem('materio-skin-variant')
                ? localStorage.getItem('materio-skin-variant')
                : dashboardThemeConfig.app.skinVariant,
            contentWidth: dashboardThemeConfig.app.contentWidth,
        },
        menu: {
            isMenuHidden: dashboardThemeConfig.menu.isMenuHidden,
            isVerticalNavMini: dashboardThemeConfig.menu.isVerticalNavMini,
        },
        appBar: {
            type: dashboardThemeConfig.appBar.type,
            isBlurred: dashboardThemeConfig.appBar.isBlurred,
            height: dashboardThemeConfig.appBar.height,
        },
        footer: {
            type: dashboardThemeConfig.footer.type,
        },
        themes: dashboardThemeConfig.themes,
    },
    mutations: {
        UPDATE_APP_ROUTE_TRANSITION(state, value) {
            state.app.routeTransition = value
        },
        UPDATE_CONTENT_LAYOUT_NAV(state, value) {
            state.app.contentLayoutNav = value
        },
        UPDATE_APP_SKIN_VARIANT(state, value) {
            state.app.skinVariant = value
        },
        UPDATE_APP_CONTENT_WIDTH(state, value) {
            state.app.contentWidth = value
        },
        TOGGLE_MENU_MENU_HIDDEN(state, value) {
            state.menu.isMenuHidden = value
        },
        TOGGLE_MENU_VERTICAL_NAV_MINI(state, value) {
            state.menu.isVerticalNavMini = value
        },
        UPDATE_APP_BAR_TYPE(state, value) {
            state.appBar.type = value
        },
        UPDATE_APP_BAR_IS_BLURRED(state, value) {
            state.appBar.isBlurred = value
        },
        UPDATE_FOOTER_TYPE(state, value) {
            state.footer.type = value
        },
        UPDATE_THEMES(state, value) {
            state.themes = value
        },
    },
}
