import { MEMBER_TEACHER } from '@apps/auth/config/members'

export default [
    {
        path: '/groups',
        name: 'groups',
        component: () => import('@/apps/school/views/Groups'),
        meta: {
            layout: 'content',
            access: {
                members: [ MEMBER_TEACHER ]
            }
        }
    },
    {
        path: '/groups/:uuid',
        name: 'groups.details',
        component: () => import('@/apps/school/views/Groups/GroupDetails'),
        redirect: {name: 'groups.students'},
        props: true,
        meta: {
            layout: 'content',
            access: {
                members: [ MEMBER_TEACHER ]
            }
        },
        children: [
            {
                path: 'students',
                name: 'groups.students',
                component: () => import('@apps/school/views/Groups/GroupsStudents'),
                props: true,
                meta: {
                    layout: 'content',
                    access: {
                        members: [ MEMBER_TEACHER ]
                    }
                }
            },
            {
                path: 'daybooks',
                name: 'groups.daybooks',
                component: () => import('@apps/school/views/Groups/GroupDayBooks'),
                props: true,
                meta: {
                    layout: 'content',
                    access: {
                        members: [ MEMBER_TEACHER ]
                    }
                }
            },
            {
                path: 'questions/shared',
                name: 'groups.questions.shared',
                component: () => import('@apps/school/views/Groups/GroupQuestionsShared'),
                props: true,
                meta: {
                    layout: 'content',
                    access: {
                        members: [ MEMBER_TEACHER ]
                    }
                }
            },
            {
                path: 'questions/statistic',
                name: 'groups.questions.statistic',
                component: () => import('@apps/school/views/Groups/GroupQuestionsStatistic'),
                props: true,
                meta: {
                    layout: 'content',
                    access: {
                        members: [ MEMBER_TEACHER ]
                    }
                }
            },
            {
                path: 'q/:quuid/results',
                name: 'groups.questions.results',
                component: () => import('@apps/school/views/Groups/GroupQuestionsResults'),
                props: true,
                meta: {
                    layout: 'content',
                    access: {
                        members: [ MEMBER_TEACHER ]
                    }
                }
            },
        ]
    }
]
