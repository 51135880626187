import mbCheck from '@/mixins/mobileBreakpointChecker'

export default {
    name: 'WBtnAction',
    props: {
        options: {
            type: Object,
            default: function () {
                return {}
            }
        },
        to: {
            type: [ Object, String ],
            default: undefined
        },
        text: {
            type: String,
            default: undefined
        },
        icon: {
            type: String,
            default: undefined
        },
        color: {
            type: String,
            default: 'primary'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        large: {
            type: Boolean,
            default: false
        },
        xLarge: {
            type: Boolean,
            default: false
        },
        small: {
            type: Boolean,
            default: false
        },
        xSmall: {
            type: Boolean,
            default: false
        }
    },
    mixins: [ mbCheck ],
    computed: {
        isText() {
            return !this.isMobile && (this.text !== undefined || this.prefix !== undefined)
        },
        btnPropsComputed() {
            const props = {
                text: !this.isMobile,
                color: this.color,
                disabled: this.disabled,
                to: this.to,
                large: this.large,
                xLarge: this.xLarge,
                small: this.small,
                xSmall: this.xSmall
            }

            props.icon = this.isMobile || (this.text === undefined && this.prefix === undefined)

            return Object.assign(props, this.options)
        },
        prefixComputed() {
            return this.$trans(this.prefix)
        }
    },
    data() {
        return {
            prefix: undefined
        }
    }
}
