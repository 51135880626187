/* eslint-disable */
import { MEMBER_STUDENT, MEMBER_TEACHER } from '@apps/auth/config/members'

export default [
    {
        path: '/schedule',
        name: 'schedule',
        component: () => import('@apps/school/views/Schedule'),
        meta: {
            layout: 'content',
            access: {
                members: [ MEMBER_TEACHER, MEMBER_STUDENT ]
            }
        }
    },
    {
        path: '/schedule/:uuid',
        name: 'schedule.lesson',
        component: () => import('@apps/school/views/Schedule/ScheduleLesson'),
        meta: {
            layout: 'content',
            access: {
                members: [ MEMBER_TEACHER, MEMBER_STUDENT ]
            }
        }
    },
]
