import { MEMBER_STUDENT, MEMBER_TEACHER } from '@apps/auth/config/members'

export default [
    {
        path: '/questions',
        name: 'questions',
        component: () => import('@/apps/school/views/Questions/QuestionsStudentPage'),
        redirect: {name: 'questions.active'},
        meta: {
            layout: 'content',
            access: {
                members: [ MEMBER_STUDENT ]
            }
        },
        children: [
            {
                path: 'active',
                name: 'questions.active',
                component: () => import('@/apps/school/views/Questions/QuestionsActivePage'),
                meta: {
                    layout: 'content',
                    access: {
                        members: [ MEMBER_STUDENT ]
                    }
                }
            },
            {
                path: 'results',
                name: 'questions.results',
                component: () => import('@/apps/school/views/Questions/QuestionsResultsPage'),
                meta: {
                    layout: 'content',
                    access: {
                        members: [ MEMBER_STUDENT ]
                    }
                }
            },
        ]
    },
    {
        path: '/questions-manage',
        name: 'questions.manage',
        component: () => import('@/apps/school/views/Questions/QuestionsTeacherPage'),
        meta: {
            layout: 'content',
            access: {
                members: [ MEMBER_TEACHER ]
            }
        }
    },
    {
        path: '/questions-manage/:uuid/sets',
        name: 'questions.sets',
        component: () => import('@/apps/school/views/Questions/QuestionsSetsPage'),
        props: true,
        meta: {
            layout: 'content',
            access: {
                members: [ MEMBER_TEACHER ]
            }
        }
    }

]
