/* eslint-disable */
import _ from 'lodash'
// import students from './_students'
// import teachers from './_teachers'
import departments from './_departments'
import courses from './_courses'
import schedule from './_schedule'
import daybook from './_daybook'
import capacity from './_capacity'
import groups from './_groups'
import documents from './_documents'
import questions from './_questions'

export default _.concat(
    // students,
    // teachers,
    departments,
    courses,
    schedule,
    daybook,
    capacity,
    groups,
    documents,
    questions
)
