/* eslint-disable */
import request from '@/plugins/request'

const prefix = 'schools'

export const getSchoolsCollection = payload => {
    const options = {
        method: 'GET',
        url: `${prefix}`,
        params: payload
    }

    return request(options)
}

/**
 *
 * @param {Object} data
 * @returns {AxiosPromise}
 */
export const storeSchool = data => {
    const options = {
        method: 'POST',
        url: `${prefix}`,
        data: data
    }

    return request(options)
}

/**
 *
 * @param {String} uuid
 * @param {Object} data
 * @returns {AxiosPromise}
 */
export const updateSchool = (uuid, data) => {
    const options = {
        method: 'PATCH',
        url: `${prefix}/${uuid}`,
        data: data
    }

    return request(options)
}

/**
 *
 * @param {String} uuid
 * @returns {AxiosPromise}
 */
export const deleteSchool = uuid => {
    const options = {
        method: 'DELETE',
        url: `${prefix}/${uuid}`
    }

    return request(options)
}

// /**
//  *
//  * @param {Object} data
//  * @returns {AxiosPromise}
//  */
// export const resortSchools = data => {
//     const options = {
//         method: 'PATCH',
//         url: `${prefix}/resort`,
//         data: data
//     }
//
//     return request(options)
// }
