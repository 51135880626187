import { ROLE_SUPER_ADMIN } from '@apps/auth/config/roles'
import { LESSON, LOCATION, PERIOD, SCHOOL } from '@/widgets/components/WIcon/icons'

export default [
    {
        title: null,
        subtitle: null,
        hint: null,
        name: 'school',
        access: {
            roles: [ ROLE_SUPER_ADMIN ]
        },
        items: [
            {
                icon: SCHOOL,
                title: 'Schools',
                subtitle: null,
                color: null,
                component: () => import('./views/Management/SchoolsManage'),
                path: 'schools',
                name: 'schools',
            }
        ]
    },
    {
        title: null,
        subtitle: null,
        hint: null,
        name: 'school',
        access: {
            roles: [ ROLE_SUPER_ADMIN ]
        },
        items: [
            {
                icon: PERIOD,
                title: 'Study periods',
                subtitle: null,
                color: null,
                component: () => import('./views/Management/StudyPeriodsManage'),
                path: 'study-periods',
                name: 'study-periods',
            }
        ]
    },
    {
        title: null,
        subtitle: null,
        hint: null,
        name: 'school',
        access: {
            roles: [ ROLE_SUPER_ADMIN ]
        },
        items: [
            {
                icon: LESSON,
                title: 'Lesson types',
                subtitle: 'hint.manage.lesson_types',
                color: null,
                component: () => import('./views/Management/LessonTypesManage'),
                path: 'lesson-types',
                name: 'lesson-types',
            },
            {
                icon: LOCATION,
                title: 'Locations',
                subtitle: 'hint.manage.lesson_locations',
                color: null,
                component: () => import('./views/Management/LessonLocationsManage'),
                path: 'lesson-locations',
                name: 'lesson-locations',
            }
        ]
    },
]
