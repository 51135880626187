<template>
    <v-btn
        v-bind="btnPropsComputed"
        @click="$emit('click')"
    >
        <w-icon
            value="ADD"
            :left="isText"

        ></w-icon>
        <span v-if="isText">{{ prefixComputed }} {{ text }}</span>
    </v-btn>
</template>

<script>
import mbCheck from '@/mixins/mobileBreakpointChecker'
import wBtnActionMixin from '@/widgets/components/WButtons/mixins/wBtnActionMixin'

export default {
    name: 'WBtnAdd',
    mixins: [ mbCheck, wBtnActionMixin ],
    data() {
        return {
            prefix: 'Add'
        }
    }

}
</script>
