import Vue from 'vue'
import App from './App.vue'
// import 'roboto-fontface/css/roboto/roboto-fontface.css'
// import '@mdi/font/css/materialdesignicons.css'

import './registerServiceWorker'

import router from './router'
import store from './store'
// import { i18n } from './plugins/i18n'
import vuetify from './plugins/vuetify'
import '@/plugins/vue-toast-notification'
import '@/plugins/LProgress'
import '@/plugins/TextFilters'
import '@/plugins/MomentJs'
import '@apps/auth/plugins/Auth'
import '@apps/notification/plugin'
import '@/plugins/Trans'
import '@/widgets/WidgetsPlugin'
import '@/plugins/appsEventListeners'
import '@/styles/styles.scss'

import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import VueClipboard from 'vue-clipboard2'

Vue.use(VueQuillEditor, /* { default global options } */)

Vue.use(VueClipboard)

Vue.config.productionTip = false

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
