<template>
    <v-card
        v-bind="wrapperProps"
    >
        <div v-if="hasToolBar" :class="toolbarClasses">
            <div class="w-data-table__filters d-md-flex align-md-center">
                <div class="d-flex align-center">
                    <div v-if="hasIcon" class="ml-1 mr-2">
                        <slot name="icon">
                            <w-icon dense :value="icon"/>
                        </slot>
                    </div>
                    <div
                        v-if="title"
                        class="subtitle-1 font-weight-medium ml-1 mr-2 text-truncate"
                    >
                        {{ title }}
                    </div>
                </div>
                <!-- search -->
                <div v-if="searchable" class="mr-4">
                    <v-text-field
                        v-model="searchQuery"
                        :placeholder="$trans('Search')"
                        single-line
                        hide-details
                        dense
                        clearable
                        :append-icon="searchAppendIcon"
                        @click:append="onFilter('search')"
                        @keydown.enter="onFilter('search')"
                        @keydown.esc="onFilter()"
                        @click:clear="onFilter()"
                    >
                    </v-text-field>
                </div>
                <slot name="filters"></slot>
            </div>
            <v-spacer></v-spacer>
            <slot name="actions"></slot>
        </div>
        <v-divider v-if="hideDefaultHeader && hasToolBar"></v-divider>
        <v-data-table
            ref="vDataTable"
            :id="tableId"
            calculate-widths
            :item-key="itemKey"
            :headers="computedHeaders"
            :group-by="groupBy"
            :items="computedItems"
            :items-per-page="itemsPerPage"
            :server-items-length="serverItemsLength"
            :options.sync="options"
            :page.sync="currentPage"
            :search="search"
            :show-select="showSelect"
            :selectable-key="selectableKey"
            :single-select="singleSelect"
            :footer-props="computedFooterProps"
            :custom-filter="localFilter"
            :mobile-breakpoint="mobileBreakpoint"
            :hide-default-header="hideDefaultHeader"
            :hide-default-footer="hideDefaultFooter"
            :loading="loading"
            @click:row="onRowClick"
        >
            <template
                v-for="headerItem in computedHeaders"
                #[`item.${headerItem.value}`]="{item}"
            >
                <slot :name="`item.${headerItem.value}`" :item="item">
                    {{ item[headerItem.value] }}
                </slot>
            </template>

            <template #item.__draggable>
                <div class="draggable-handle">
                    <w-icon value="DRAGGABLE"/>
                </div>
            </template>
        </v-data-table>
        <div v-if="hasFooter">
            <v-divider></v-divider>
            <slot name="footer"></slot>
        </div>
    </v-card>
</template>

<script>
import _ from 'lodash'
import Sortable from 'sortablejs'
import { vDataTableOptionsAdapter } from './utils'

export default {
    name: 'WDataTable',
    props: {
        color: {
            type: String,
            default: null
        },
        itemKey: {
            type: String,
            default: 'id'
        },
        showIndex: {
            type: Boolean,
            default: false
        },
        draggable: {
            type: Boolean,
            default: false
        },
        noGutters: {
            type: Boolean,
            default: false
        },
        hideDivider: {
            type: Boolean,
            default: false
        },
        draggableKey: {
            type: String,
            default: 'weight'
        },
        showSelect: {
            type: Boolean,
            default: false
        },
        singleSelect: {
            type: Boolean,
            default: false
        },
        selectableKey: {
            type: String,
            default: 'id'
        },
        title: {
            type: String,
            default: undefined
        },
        icon: {
            type: String,
            default: undefined
        },
        loading: {
            type: Boolean,
            default: false
        },
        hideDefaultFooter: {
            type: Boolean,
            default: false
        },
        hideDefaultHeader: {
            type: Boolean,
            default: false
        },
        itemsPerPage: {
            type: Number,
            default: 25
        },
        footerProps: {
            type: Object,
            default: function () {
                return {}
            }
        },
        page: {
            type: [ Number, String ],
            default: null
        },
        searchable: {
            type: Boolean,
            default: false
        },
        tile: {
            type: Boolean,
            default: false
        },
        flat: {
            type: Boolean,
            default: false
        },
        outlined: {
            type: Boolean,
            default: false
        },
        headers: {
            type: Array,
            default: function () {
                return []
            }
        },
        groupBy: {
            type: String,
            default: undefined
        },
        items: {
            type: Array,
            default: function () {
                return []
            }
        },
        serverItemsLength: {
            type: Number,
            default: undefined
        },
        mobileBreakpoint: {
            type: [ Number, String ],
            default: 0
        },
        filterBy: {
            type: Array,
            default: function () {
                return []
            }
        },
        rowClick: {
            type: Function,
            default: undefined
        }
    },
    computed: {
        hasToolBar() {
            return !!this.searchable ||
                !!this.title ||
                !!this.$slots.actions ||
                !!this.$slots.filters ||
                !!this.$slots.icon
        },
        hasIcon() {
            return !!this.icon ||
                !!this.$slots.icon
        },
        hasFooter() {
            return !!this.$slots.footer
        },
        toolbarClasses() {
            let classes = 'd-flex align-center'

            if(this.hasToolBar) {
                classes += ' pa-3'
            }

            return classes
        },
        wrapperProps() {
            return {
                outlined: this.outlined,
                tile: this.tile,
                flat: this.flat,
                class: this.wrapperClasses,
                color: this.color
            }
        },
        wrapperClasses() {
            const classes = [ 'w-data-table' ]

            if(this.rowClick || (this.$listeners && this.$listeners['click:row'])) {
                classes.push('w-data-table--row-clickable')
            }

            if(Number(this.mobileBreakpoint) === 0) {
                classes.push('w-data-table--not-responsive')
            }

            if(this.noGutters) {
                classes.push('w-data-table--not-gutters')
            }

            if(this.hideDivider) {
                classes.push('w-data-table--hide-divider')
            }

            if(this.hideDefaultFooter) {
                classes.push('pb-1')
            }

            return classes
        },
        computedHeaders() {
            const headers = this.headers

            if(headers.length === 0) {
                return [
                    {
                        text: '#',
                        value: '__index',
                        sortable: false,
                        width: 30
                    }
                ]
            }

            const result = []

            if(this.showIndex) {
                result.push({
                    text: '#',
                    value: '__index',
                    sortable: false,
                    width: 60
                })
            }

            if(this.draggable) {
                result.push({
                    value: '__draggable',
                    sortable: false,
                    width: 60
                })
            }

            if(headers.length > 1 && Number(this.mobileBreakpoint) === 0 && this.$vuetify.breakpoint.smAndDown) {
                const first = _.first(headers)
                const last = _.last(headers)
                return _.concat(result, first, last)
            } else {
                return _.concat(result, headers)
            }
        },
        computedItems() {
            const items = this.tableItems

            if(this.showIndex) {
                items.map((o, i) => {
                    o.__index = i + 1
                })
            }

            if(this.draggable) {
                items.map((o, i) => {
                    if(!o[this.draggableKey]) {
                        o[this.draggableKey] = i
                    }
                })
            }

            return items
        },
        search() {
            return this.serverItemsLength ? undefined : this.searchQuery
        },
        searchAppendIcon() {
            return 'mdi-magnify'
        },
        computedFooterProps() {
            const defaultProps = {
                itemsPerPageOptions: [ 25, 50, 100, -1 ]

            }

            return Object.assign(defaultProps, this.footerProps)
        }
    },
    watch: {
        options(options) {
            const payload = vDataTableOptionsAdapter(options)

            if(this.searchQuery) {
                payload.search = this.searchQuery
            }

            this.$emit('change:options', payload)
        },
        items(items) {
            this.tableItems = items

            if(this.draggable) {
                this.tableItems.map((o, i) => {
                    o[this.draggableKey] = i
                })
            }

            this.setSort()
        },
        page(value) {
            if(value) {
                this.currentPage = value
            }
        }
    },
    mounted() {
        this.tableItems = _.concat([], this.items)
        this.setSort()

        this.$nextTick(() => {
            this.setSizeCells()
        })
    },
    data() {
        return {
            tableKey: 0,
            tableId: 'w-data-table-' + _.random(100, 1000),
            tableItems: [],
            options: {},
            collection: [],
            searchQuery: '',
            currentPage: 1
        }
    },
    methods: {
        setSort() {
            if(!this.draggable) {
                return
            }

            const el = document.querySelector(`#${ this.tableId } .v-data-table__wrapper table tbody`)

            if(!el) {
                return
            }

            this.sortable = Sortable.create(el, {
                ghostClass: 'draggable-ghost', // Class name for the drop placeholder,
                handle: '.draggable-handle',
                setData: function (dataTransfer) {
                    dataTransfer.setData('Text', '')
                    // to avoid Firefox bug
                    // Detail see : https://github.com/RubaXa/Sortable/issues/1012
                },
                onEnd: evt => {
                    this.sortableItems(evt)
                }
            })
        },
        sortableItems(evt) {
            // const tableItems = this.tableItems
            const targetRow = this.tableItems.splice(evt.oldIndex, 1)[0]
            this.tableItems.splice(evt.newIndex, 0, targetRow)

            for (const index in this.tableItems) {
                this.tableItems[index][this.draggableKey] = index
            }

            this.$emit('dragged', this.tableItems.map(o => ({
                [this.itemKey]: o[this.itemKey],
                [this.draggableKey]: o[this.draggableKey]
            })))
        },
        setSizeCells() {
            const el = document.querySelector(`#${ this.tableId } .v-data-table__wrapper table colgroup`)

            if(!el) {
                return
            }

            el.childNodes.forEach((col, i) => {
                const isSelect = this.showSelect && i === 0
                const isDraggable = this.draggable && i === 0
                const isSelectDraggable = (this.showSelect && i === 0) || (this.draggable && i === 1)
                if(isSelect || isDraggable || isSelectDraggable) {
                    col.classList.add('dense-cell')
                }
            })
        },
        onChangeOptions(payload) {
            this.$emit('change:options', payload)
        },
        localFilter(value, search, item) {
            for (let i = 0; i < this.filterBy.length; i++) {
                if(_.includes(_.toLower(_.get(item, this.filterBy[i])), _.toLower(search))) {
                    return true
                }
            }
        },
        onFilter(filter) {
            const options = vDataTableOptionsAdapter(this.options)

            if(filter === 'search' && this.searchQuery) {
                const search = { search: this.searchQuery }
                const payload = this.serverItemsLength ? Object.assign(search, options, { page: 1 }) : search

                this.onChangeOptions(payload)
            } else {
                this.searchQuery = null
                this.onChangeOptions(Object.assign(options, { page: 1 }))
            }
        },
        onRowClick(item) {
            if(this.rowClick) {
                this.rowClick(item)
            } else {
                this.$emit('click:row', item)
            }
        }
    }
}
</script>

<style lang="scss">
.w-data-table {
    &--not-responsive {
    }

    .dense-cell {
        max-width: 60px;
        padding: 0;
    }

    &--not-gutters {
        .v-data-table__wrapper table tbody tr:not(.v-data-table__empty-wrapper) td {
            height: auto !important;
            padding: 0 !important;
        }
    }

    &--hide-divider {
        .v-data-table__wrapper table tbody tr td {
            border: none !important;
        }
    }

    &--row-clickable {
        .v-data-table__wrapper table tbody tr {
            cursor: pointer;
        }
    }

    .draggable-handle {
        cursor: grab;
    }

    .sortable-chosen {
        .draggable-handle {
            cursor: grabbing;
        }
    }

    &__filters {
        width: 100%;
    }

    .draggable-ghost {
        background-color: #efedfd;
    }

    @media screen and (min-width: 960px) {
        &__filters {
            > * {
                max-width: 230px;
            }

            > * + * {
                margin-left: 8px !important;
            }
        }
    }

    @media screen and (max-width: 960px) {
        &__filters {
            > * + * {
                margin-top: 12px !important;
            }
        }
    }

}
</style>
