import _ from 'lodash'
import hasWSimpleFormProps from '@/widgets/components/WSimpleForm/mixins/hasWSimpleFormProps'
import Debugger from '@/utils/Debugger'

export default {
    mixins: [
        hasWSimpleFormProps
    ],
    components: {
        hidden: () => import('../components/WHiddenField'),
        'v-text-field': () => import('vuetify/lib/components/VTextField'),
        'v-textarea': () => import('vuetify/lib/components/VTextarea'),
        'v-select': () => import('vuetify/lib/components/VSelect'),
        'v-switch': () => import('vuetify/lib/components/VSwitch'),
        'v-checkbox': () => import('vuetify/lib/components/VCheckbox/VCheckbox'),
        'v-autocomplete': () => import('vuetify/lib/components/VAutocomplete'),
        'v-date-picker': () => import('vuetify/lib/components/VDatePicker/VDatePicker'),
    },
    watch: {
        item() {
            this.resetItem()
        },
        errors(items) {
            this.formErrors = items
        },
        locale() {
            this.resetForm()
        }
    },
    mounted() {
        this.resetItem()
        this.resetForm()

        this.$nextTick(() => {
            // Call observe
            this._callObserveHandler('onMounted')
        })

        document.addEventListener('keyup', this.onKeyupEnter)
    },
    updated() {
        this.$nextTick(() => {
            // Call observe
            this._callObserveHandler('onUpdated')
        })
    },
    destroyed() {
        // Debugger.log(this.$options.name, 'destroyed')
        document.removeEventListener('keyup', this.onKeyupEnter)
    },
    data() {
        return {
            isValid: true,
            isChanged: false,
            formErrors: this.errors,
            formData: {}
        }
    },
    computed: {
        locale() {
            return this.$store.getters.locale
        },
        isEditing() {
            return !_.isEmpty(this.item)
        },
        isDeletable() {
            return this.isEditing && this.deletable
        },
        action() {
            return {
                text: this.isEditing ? this.$trans(this.updateText) : this.$trans(this.saveText),
                action: this.isEditing ? 'update' : 'save'
            }
        }
    },
    methods: {
        _callObserveHandler(handler, options) {
            if (
                this.observer &&
                this.observer[handler] &&
                typeof this.observer[handler] === 'function'
            ) {
                const handlerOptions = options
                    ? Object.assign({}, options)
                    : {}

                handlerOptions.vm = this

                this.observer[handler](handlerOptions)
            } else if (
                this.observer &&
                this.observer[handler] &&
                typeof this.observer[handler] !== 'function'
            ) {
                Debugger.warn(`${this.$options.name}.observer.${handler} must be a function, ${typeof handler} given`)
            }
        },
        resetItem() {
            this.formData = Object.assign({}, this.formData, this.item)

            for (const formDataKey in this.formData) {
                if (Number.isInteger(this.formData[formDataKey])) {
                    this.formData[formDataKey] = this.formData[formDataKey].toString()
                }
            }

            this.isValid = true
        },
        resetErrorMessages() {
            this.formErrors = []
        },
        resetForm() {
            this.$refs.wSimpleForm.resetValidation()
            this.resetErrorMessages()
        },
        getErrors(fieldName) {
            return _.get(this.formErrors, fieldName, undefined)
        },
        validate() {
            return this.$refs.wSimpleForm.validate()
        },
        makeSubmitData() {
            if(this.fields.length === 0) {
                return this.formData
            }

            const data = {}

            for (let i = 0; i < this.fields.length; i++) {
                const field = this.fields[i]

                if (_.get(field, 'props.readonly', false) ||
                    _.get(field, 'props.disabled', false)) {
                    continue
                }

                const filter = field.filter
                let value = _.get(this.formData, field.name, null)

                if(filter && typeof filter === 'function') {
                    value = filter(value)
                }

                _.set(data, field.name, value)
            }

            return data
        },
        onInput(field, value) {
            // console.log(this.$options.name, 'onInput', field, value)

            this.isChanged = true

            // Call observe
            this._callObserveHandler('onInput', { field, value })

            this.$emit('input', field, value, this.formData, this.resetForm)
            this.$emit(`input:${field}`, value, this.formData, this.resetForm)
        },
        onChange(field, value) {
            // console.log(this.$options.name, 'onChange', field, value)

            this.isChanged = true

            // Call observe
            this._callObserveHandler('onChange', { field, value })

            this.$emit('change', field, value, this.formData)
            this.$emit(`change:${field}`, value, this.formData)
        },
        onKeyupEnter(e) {
            if(!e.cancelable) return

            e.stopPropagation()
            e.preventDefault()

            if (e.keyCode === 13 && this.isChanged) {
                this.onSubmit()
            }
        },
        onSubmit() {
            if (!this.$refs.wSimpleForm.validate()) {
                return
            }

            if(this.formValidation && !this.formValidation()) {
                this.resetForm()
                this.isValid = false
                return
            }

            const data = this.makeSubmitData()

            // Call observe
            this._callObserveHandler('onSubmit', {
                action: this.action.action,
                data: data,
                item: this.item
            })

            this.$emit(this.action.action, data, this.item)
            this.$emit('submit', data, this.action.action, this.item)
        },
        onDelete() {
            const data = this.makeSubmitData()

            // Call observe
            this._callObserveHandler('onDelete', {
                action: 'delete',
                data: data,
                item: this.item
            })

            this.$emit('delete', data, this.item)
            this.$emit('submit', data, 'delete', this.item)
        }
    }
}
