import Cookies from 'js-cookie'
import store from '@/store'
import { MEMBER_EMPLOYEE, MEMBER_STUDENT, MEMBER_TEACHER } from '@apps/auth/config/members'
import { ROLE_ADMIN, ROLE_ASSISTANT, ROLE_CURATOR, ROLE_MANAGER, ROLE_SUPER_ADMIN } from '@apps/auth/config/roles'

const tokenKey = 'ec.sid'

export function getToken() {
    return Cookies.get(tokenKey)
}

export function setToken(token) {
    return Cookies.set(tokenKey, token)
}

export function removeToken() {
    return Cookies.remove(tokenKey)
}

export const AuthProvider = class {
    static loginUri = '/auth/login'
    static forgotPasswordUri = '/auth/forgot-password'
    static resetPasswordUri = '/auth/reset-password'

    get user() {
        return store.state.auth.user
    }

    get member() {
        return store.state.auth.member
    }

    get token() {
        return getToken()
    }

    get memberType() {
        return store.state.auth.memberType
    }

    get roles() {
        return store.state.auth.roles
    }

    get permissions() {
        return store.state.auth.permissions
    }

    /**
     *
     * @param {string} resourceType
     * @param {string} resourceId
     * @returns {boolean}
     */
    hasPermission(resourceType, resourceId) {
        return !!this.permissions.find(o => {
            return o.resource_type === resourceType &&
                o.resource_id === resourceId
        })
    }

    /**
     *
     * @param {Array|null} members
     * @param {Array|null} roles
     * @return Boolean
     */
    hasAccess({ members, roles }) {
        const isMembersAvailable = members && Array.isArray(members)
        const isRolesAvailable = roles && Array.isArray(roles)
        const authRoles = this.roles || []

        if (!isMembersAvailable && !isRolesAvailable) {
            return false
        }

        const isMember = isMembersAvailable && members.includes(this.memberType)
        const hasRole = isRolesAvailable && roles.filter(o => authRoles.includes(o)).length > 0

        // if (isMembersAvailable && isRolesAvailable) {
        //     return isMember && hasRole
        // } else if (isMembersAvailable && !isRolesAvailable) {
        //     return isMember
        // } else if (!isMembersAvailable && isRolesAvailable) {
        //     return hasRole
        // }

        return isMember || hasRole
    }

    isEmployee() {
        return this.memberType === MEMBER_EMPLOYEE
    }

    isStudent() {
        return this.memberType === MEMBER_STUDENT
    }

    isTeacher() {
        return this.memberType === MEMBER_TEACHER
    }

    isSuperAdmin() {
        return this.roles.includes(ROLE_SUPER_ADMIN)
    }

    isAdmin() {
        return this.roles.includes(ROLE_ADMIN)
    }

    isManager() {
        return this.roles.includes(ROLE_MANAGER)
    }

    isAssistant() {
        return this.roles.includes(ROLE_ASSISTANT)
    }

    isCurator() {
        return this.roles.includes(ROLE_CURATOR)
    }

    check() {
        return this.token !== null && this.user !== null
    }

    getToken() {
        return getToken()
    }

    locale() {
        return store.getters.locale
    }

    resetToken() {
        return store.dispatch('auth/resetToken')
    }

    async fetchAuthUser() {
        if(this.user) return

        await store.dispatch('auth/fetchAuthUser')
    }
}

export default {
    AuthProvider
}
