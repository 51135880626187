<template>
    <v-navigation-drawer
        v-model="isDrawer"
        :right="!$vuetify.rtl"
        touchless
        app
        temporary
        :width="width"
    >
        <div class="w-navigation-drawer__container">
            <div class="drawer-header d-flex align-center">
                <div class="text-truncate">
                    <slot name="title">
                        <span
                            v-if="isTitle"
                            class="font-weight-semibold text-base text--primary"
                        >
                            {{ title }}
                        </span>
                    </slot>
                </div>
                <v-spacer></v-spacer>
                <v-btn
                    icon
                    @click="$emit('input', false)"
                >
                    <w-icon value="CLOSE"/>
                </v-btn>
            </div>
            <v-divider></v-divider>
            <v-progress-linear
                :active="loading"
                height="2"
                indeterminate
                absolute
                color="primary"
            />
            <div class="w-navigation-drawer__content">
                <slot></slot>
            </div>
        </div>
    </v-navigation-drawer>
</template>

<script>

export default {
    name: 'WAsideDrawer',
    props: {
        value: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: undefined
        },
        width: {
            type: [ String, Number ],
            default: 420
        },
        loading: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        isTitle() {
            return this.title !== undefined || this.$slots.title
        }
    },
    watch: {
        value(flag) {
            this.isDrawer = flag

            if (flag) {
                document.body.classList.add(this.bodyClassName)
            } else {
                document.body.classList.remove(this.bodyClassName)
            }
        },
        isDrawer(flag) {
            this.$emit('input', flag)

            if (flag) {
                document.body.classList.add(this.bodyClassName)
            } else {
                document.body.classList.remove(this.bodyClassName)
            }
        }
    },
    data() {
        return {
            isDrawer: false,
            bodyClassName: 'aside-drawer-is-open'
        }
    },
    mounted() {
        this.$emit('input', this.isDrawer)
        window.addEventListener('keydown', this.keyDownHandler)
    },
    destroyed() {
        window.removeEventListener('keydown', this.keyDownHandler)
    },
    methods: {
        keyDownHandler(event) {
            if (event.code === 'Escape') {
                this.isDrawer = false
            }
        }
    }
}
</script>

<style lang="scss">
    body.aside-drawer-is-open {
        max-height: 100vh;
        overflow: hidden;
    }
    .w-navigation-drawer {
        &__container {
            height: 100%;
            overflow: hidden;
        }

        &__content {
            height: calc(100% - 44px - 24px - 2px);
            padding-bottom: 10px;
            overflow-x: hidden;
            overflow-y: auto;
        }
    }
    .ps-calendar-event-handler {
        height: calc(100% - 44px - 24px - 2px);
    }
</style>
