import Vue from 'vue'
import { getQuestionsSettings } from '@apps/questions/api'

const state = {
    scores_range: [],
    questions_default_options: {},
    questions_shared_default_options: {}
}

const getters = {
    scoresRange: state => state.scores_range,
    questionsDefaultOptions: state => state.questions_default_options,
}

const mutations = {
    SET_SETTINGS: (state, values) => {
        for (const stateKey in state) {
            if(Object.keys(values).includes(stateKey)) {
                Vue.set(state, stateKey, values[stateKey])
            }
        }
    }
}

const actions = {

    // get settings
    async fetchSettings({ commit }) {
        try {
            const response = await getQuestionsSettings()
            await commit('SET_SETTINGS', response.data.data)
        } catch (error) {
            // ...
        }
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
