import { login, logout, fetchAuthUser, updateAuthSettings } from './api'
import { getToken, setToken, removeToken } from './utils'
import { resetRouter } from '@/router'
import Vue from 'vue'
import EventEmitter from '@/plugins/EventEmitter'

const state = {
    token: getToken(),
    user: null,
    member: null,
    memberType: null,
    isSuperAdmin: false,
    permissions: [],
    roles: [],
    settings: {
        locale: null
    }
}

const getters = {
    user: state => state.user,
    member: state => state.member,
    memberType: state => state.memberType,
    isSuperAdmin: state => state.isSuperAdmin,
    roles: state => state.roles,
    permissions: state => state.permissions,
    settings: state => state.settings
}

const mutations = {
    RESET_STATE: (state) => {
        Vue.set(state, 'user', null)
        Vue.set(state, 'member', null)
        Vue.set(state, 'memberType', null)
        Vue.set(state, 'isSuperAdmin', false)
        Vue.set(state, 'roles', [])
        Vue.set(state, 'permissions', [])

        Vue.set(state, 'settings', {
            locale: null
        })

        Vue.set(state, 'token', getToken())
    },
    SET_TOKEN: (state, token) => {
        state.token = token
    },
    SET_USER: (state, user) => {
        Vue.set(state, 'user', user)
    },
    SET_MEMBER: (state, member) => {
        Vue.set(state, 'member', member)
    },
    SET_MEMBER_TYPE: (state, memberType) => {
        Vue.set(state, 'memberType', memberType)
    },
    SET_IS_SUPER_ADMIN_STATE: (state, flag) => {
        Vue.set(state, 'isSuperAdmin', flag)
    },
    SET_ROLES: (state, roles) => {
        Vue.set(state, 'roles', roles)
    },
    SET_PERMISSIONS: (state, permissions) => {
        Vue.set(state, 'permissions', [].concat(permissions))
    },
    SET_SETTINGS: (state, settings) => {
        Vue.set(state, 'settings', Object.assign(state.settings, settings))
    }
}

const actions = {
    // user login
    async login({ commit }, payload) {
        const {
            email,
            password
        } = payload

        return new Promise((resolve, reject) => {
            login({
                email: email,
                password: password
            })
                .then(response => {
                    const { data } = response
                    commit('SET_TOKEN', data.token)
                    setToken(data.token)
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    // get user info
    async fetchAuthUser({ commit }) {
        try {
            const response = await fetchAuthUser()
            const { data } = response

            await commit('SET_USER', data.user)
            await commit('SET_MEMBER', data.member)
            await commit('SET_MEMBER_TYPE', data.member_type)
            await commit('SET_IS_SUPER_ADMIN_STATE', data.is_super_admin)
            await commit('SET_ROLES', data.roles)
            await commit('SET_PERMISSIONS', data.permissions)
            await commit('SET_SETTINGS', data.settings)
            await commit('SET_TOKEN', getToken())

            EventEmitter.emit('change:locale', data.settings.locale || 'en')

            return data
        } catch (error) {
            if(error && error.response && Number(error.response.status) === 401) {
                removeToken() // must remove  token  first
                resetRouter()
                commit('RESET_STATE')
            }
        }
    },

    updateAuthSettings({ commit }, settings) {
        return new Promise((resolve, reject) => {
            updateAuthSettings(settings)
                .then(response => {
                    const { data } = response

                    commit('SET_SETTINGS', data.data)
                    resolve(data.data)
                })
        })
    },

    // user logout
    async logout({
        dispatch,
        commit,
        state
    }) {
        try {
            await logout(state.token)
            await dispatch('resetToken')
        } catch (error) {
            await logout(state.token)
            await dispatch('resetToken')
        }
    },

    // remove token
    resetToken() {
        return new Promise(resolve => {
            removeToken() // must remove  token  first
            resetRouter()
            // commit('RESET_STATE')
            resolve()
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
