/* eslint-disable */
export default [
    {
        path: '/auth/login',
        name: 'auth.login',
        component: () => import('./views/Login'),
        meta: {
            layout: 'blank'
        }
    },
    {
        path: '/auth/forgot-password',
        name: 'auth.forgot_password',
        component: () => import('./views/ForgotPassword'),
        meta: {
            layout: 'blank'
        }
    },
    {
        path: '/auth/reset-password',
        name: 'auth.reset_password',
        component: () => import('./views/ResetPassword'),
        meta: {
            layout: 'blank'
        }
    },
    {
        path: '/auth/profile',
        name: 'auth.profile',
        component: () => import('./views/AuthProfile'),
        meta: {
            layout: 'content'
        }
    },
    {
        path: '/auth/settings',
        name: 'auth.settings',
        component: () => import('./views/AuthSettings'),
        meta: {
            layout: 'content'
        }
    }
]
