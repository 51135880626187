/* eslint-disable */
import appsSettingsProvider from '@apps/settings/appsSettingsProvider'

const manageRoutes = appsSettingsProvider.manage.routers
const settingsRoutes = appsSettingsProvider.settings.routers

export default [
    {
        path: '/settings',
        name: 'settings',
        component: () => import('@apps/settings/views/SettingsIndex'),
        meta: {
            layout: 'content',
            target: 'settings',
            label: 'Settings'
        }
    },
    {
        path: '/manage',
        name: 'manage',
        component: () => import('@apps/settings/views/SettingsIndex'),
        meta: {
            layout: 'content',
            target: 'manage',
            label: 'Library'
        }
    },
    ...settingsRoutes,
    ...manageRoutes
]
