/* eslint-disable */
import request from '@/plugins/request'

const prefix = 'settings'

/**
 *
 * @param {String} tag
 * @return {AxiosPromise}
 */
export const getSettingsSet = (tag) => {
    const options = {
        method: 'GET',
        url: `${prefix}`,
        params: { tag }
    }

    return request(options)
}

/**
 *
 * @param {String} tag
 * @return {AxiosPromise}
 */
export const getSettings = (uuid) => {
    const options = {
        method: 'GET',
        url: `${prefix}/${uuid}`
    }

    return request(options)
}

/**
 *
 * @param {String} tag
 * @return {AxiosPromise}
 */
export const setSettings = (data) => {
    const options = {
        method: 'POST',
        url: `${prefix}`,
        data: data
    }

    return request(options)
}

/**
 *
 * @param {String} tag
 * @return {AxiosPromise}
 */
export const deleteSettings = (uuid) => {
    const options = {
        method: 'DELETE',
        url: `${prefix}/${uuid}`
    }

    return request(options)
}
