import _ from 'lodash'
import axios from 'axios'
import Vue from 'vue'
import Notification from '@/apps/notification/utils'
import Debugger from '@/utils/Debugger'
import router from '@/router'
import { AuthProvider, removeToken } from '@apps/auth/utils'

const request = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    // timeout: 10000 // request timeout
})

request.interceptors.request.use(config => {
    const Auth = new AuthProvider()
    const token = Auth.getToken()

    if (token) {
        config.headers['X-Auth-Token'] = token
        config.headers.Authorization = 'Bearer ' + token
    }

    config.headers['X-Auth-App'] = 'ecenter-web'
    config.headers['X-Accept-Locale'] = Auth.locale() || ''

    return config
}, error => Promise.reject(error))

// response interceptor
request.interceptors.response.use(response => {
    const res = response

    // Debugger.log('plugins/api.success: ', res)

    // Set message response if exist
    if (Notification.isEnabled() && _.has(res, 'data.message')) {
        const notify = new Notification(res.data.message, {
            type: Notification.TYPE.SUCCESS
        })

        notify.open()
    }

    if(Notification.isDisabled()) {
        Notification.enabled()
    }

    return response
}, error => {
    const res = error.response

    Debugger.log('plugins/api.error: ', res) // For debug

    let message

    if (_.has(res, 'data.message')) {
        message = res.data.message
    } else if (_.has(res, 'statusText')) {
        message = res.statusText
    }

    if (Notification.isEnabled() && message) {
        const notify = new Notification(message, {
            type: Notification.TYPE.ERROR
        })

        notify.open()
    }

    if(Notification.isDisabled()) {
        Notification.enabled()
    }

    if (res && res.status && Number(res.status) === 404) {
        if(window.location.pathname !== '/error-404') {
            router.push({ name: 'error-404' })
        }
    }

    if (res && res.status && Number(res.status) === 401) {
        removeToken()

        if(window.location.pathname !== AuthProvider.loginUri) {
            router.push({ name: 'auth.login' })
        }
    }

    // const errorPages = store.getters.errorPages

    return Promise.reject(error)
})

Vue.prototype.$http = request

export default request
