<template>
    <v-dialog
        :value="value"
        :width="width"
        :persistent="persistent"
        :scrollable="scrollable"
        @input="onInput(false)"
        @keydown.esc="!persistent ? onInput(false) : null"
        @click:outside="!persistent ? onInput(false) : null"
    >
        <div class="white">
            <div class="drawer-header theme-gray-bg d-flex align-center">
                <div v-if="icon">
                    <w-icon left :value="icon"/>
                </div>
                <div
                    v-if="isTitle"
                    class="font-weight-semibold text-base text--primary text-truncate"
                    style="padding: 0.4rem 0"
                >
                    {{ title }}
                </div>
                <v-spacer></v-spacer>
                <v-btn
                    v-if="!persistent"
                    icon
                    color="primary"
                    @click="onInput(false)"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </div>
            <v-divider></v-divider>
            <v-progress-linear
                :active="loading"
                height="2"
                indeterminate
                color="primary"
            />
            <v-card tile :disabled="disabled || loading">
                <slot></slot>
            </v-card>
        </div>
    </v-dialog>
</template>

<script>
export default {
    name: 'WDialogDrawer',
    props: {
        value: {
            type: Boolean,
            default: false
        },
        icon: {
            type: String,
            default: undefined
        },
        title: {
            type: String,
            default: undefined
        },
        width: {
            type: [String, Number],
            default: 420
        },
        loading: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        persistent: {
            type: Boolean,
            default: false
        },
        scrollable: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        isTitle() {
            return this.title !== undefined || this.$slots.title
        }
    },
    data() {
        return {
            isDrawer: false
        }
    },
    methods: {
        onInput(value) {
            this.$emit('input', value)
        }
    }
}
</script>

<style lang=scss>

</style>
