import store from '@/store'

export default async () => {
    await store.dispatch('school/fetchSchools')
    await store.dispatch('school/fetchLevels')
    await store.dispatch('school/fetchDepartments')
    await store.dispatch('school/fetchCallSchedule')
    await store.dispatch('school/fetchStudyPeriods')
    await store.dispatch('school/fetchLessonTypes')
    await store.dispatch('school/fetchScores')
}
