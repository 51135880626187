// Register apps services
// https://webpack.js.org/guides/dependency-management/#requirecontext

const modulesFiles = require.context('@/apps', true, /store.js$/)

export default modulesFiles.keys().reduce((modules, path) => {
    const moduleName = path.replace(/^\.\/(.*)(\/store)\.\w+$/, '$1')
    const value = modulesFiles(path)
    modules[moduleName] = value.default

    return modules
}, {})
