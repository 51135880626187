import { MEMBER_TEACHER } from '@apps/auth/config/members'

export default [
    {
        path: '/documents',
        name: 'documents',
        component: () => import('@/apps/school/views/Docs'),
        meta: {
            layout: 'content',
            access: {
                members: [ MEMBER_TEACHER ]
            }
        }
    }
]
