<template>
    <div :class="wrapperClasses">
        <div class="w-actions-drawer__content">
            <slot></slot>
        </div>
        <div class="w-actions-drawer__actions d-flex align-center">
            <slot name="actions"></slot>
        </div>
    </div>
</template>

<script>
import mobileBreakpointChecker from '@/mixins/mobileBreakpointChecker'

export default {
    name: 'WActionsDrawer',
    mixins: [ mobileBreakpointChecker ],
    computed: {
        wrapperClasses() {
            const classes = []
            classes.push('w-actions-drawer')

            if (this.isMobile) {
                classes.push('w-actions-drawer--visible')
            }

            return classes
        }
    }
}
</script>

<style lang=scss>
.w-actions-drawer {
    overflow: hidden;
    position: relative;

    &__actions {
        background: white;
        position: absolute;
        right: 12px;
        top: 5%;
        z-index: 1;
        height: 90%;
        opacity: 0;
        transition: opacity .11s;
    }

    &--visible {
        .w-actions-drawer__actions {
            opacity: 1;
        }
    }

    &:hover, &:active {
        .w-actions-drawer__actions {
            opacity: 1;
        }
    }
}
</style>
