import { AuthProvider } from '@apps/auth/utils'
import appsSettingsProvider from '@apps/settings/appsSettingsProvider'
import { MANAGE, SETTINGS } from '@/widgets/components/WIcon/icons'

export default [
    {
        event: 'init:nav',
        listener: async menu => {
            const auth = new AuthProvider()
            const hasAvailableSettings = o => (o.access && auth.hasAccess(o.access)) || !o.access

            const isAvailableManage = appsSettingsProvider.manage.settings.some(hasAvailableSettings)
            const isAvailableSettings = appsSettingsProvider.settings.settings.some(hasAvailableSettings)

            if (isAvailableManage) {
                menu.push({
                    title: 'Library',
                    icon: MANAGE,
                    to: { name: 'manage' }
                })
            }

            if (isAvailableSettings) {
                menu.push({
                    title: 'Settings',
                    icon: SETTINGS,
                    to: { name: 'settings' }
                })
            }
        }
    }
]
