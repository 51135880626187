import { EMPLOYEE, PERMISSIONS } from '@/widgets/components/WIcon/icons'
import { ROLE_ADMIN, ROLE_SUPER_ADMIN } from '@apps/auth/config/roles'

export default [
    {
        title: null,
        subtitle: null,
        name: 'employee',
        hint: 'hint.manage.employee',
        access: {
            roles: [ ROLE_SUPER_ADMIN, ROLE_ADMIN ]
        },
        items: [
            {
                icon: EMPLOYEE,
                title: 'Employees',
                subtitle: null,
                color: null,
                component: () => import('./views/Management/EmployeesManage'),
                name: 'emp',
                path: 'emp'
            },
            {
                icon: PERMISSIONS,
                title: 'Access settings',
                subtitle: null,
                color: null,
                component: () => import('./views/Management/AccessManage'),
                name: 'access',
                path: 'access'
            }
        ]
    }
]
