import store from '@/store'

export default [
    {
        event: 'change:locale',
        listener: async (locale) => {
            await store.dispatch('auth/updateAuthSettings', { locale })

            localStorage.setItem('ec.locale', locale)
        }
    }
]
