import { getMediaSettings } from '@apps/media/api'

export default {
    namespaced: true,
    state: {
        availableFileTypes: [],
        availableImageTypes: [],
        availableAudioTypes: [],
        enabledFileTypes: [],
    },
    getters: {
        availableFileTypes: state => state.availableFileTypes,
        availableImageTypes: state => state.availableImageTypes,
        availableAudioTypes: state => state.availableAudioTypes,
        enabledFileTypes: state => state.enabledFileTypes,
    },
    mutations: {
        SET_SETTINGS(state, values) {
            for (const valuesKey in values) {
                if(state[valuesKey]) {
                    state[valuesKey] = values[valuesKey]
                }
            }
        }

    },
    actions: {
        async fetchSettings({ commit }) {
            await getMediaSettings()
                .then(response => {
                    const values = response.data.data
                    commit('SET_SETTINGS', values)
                })
        }
    }
}
