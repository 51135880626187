/* eslint-disable */
import request from '@/plugins/request'

const prefix = 'study-periods'

/**
 * @returns {AxiosPromise}
 */
export const getStudyPeriods = () => {
    const options = {
        method: 'GET',
        url: `${prefix}`
    }

    return request(options)
}

/**
 *
 * @param {Object} values
 * @returns {AxiosPromise}
 */
export const storeStudyPeriod = values => {
    const options = {
        method: 'POST',
        url: `${prefix}`,
        data: values
    }

    return request(options)
}

/**
 *
 * @param {String} uuid
 * @param {Object} values
 * @returns {AxiosPromise}
 */
export const updateStudyPeriod = (uuid, values) => {
    const options = {
        method: 'PATCH',
        url: `${prefix}/${uuid}`,
        data: values
    }

    return request(options)
}

/**
 *
 * @param {String} uuid
 * @returns {AxiosPromise}
 */
export const deleteStudyPeriod = uuid => {
    const options = {
        method: 'DELETE',
        url: `${prefix}/${uuid}`
    }

    return request(options)
}
