/* eslint-disable */
import { MEMBER_STUDENT, MEMBER_TEACHER } from '@apps/auth/config/members'

export default [
    {
        path: '/courses',
        name: 'courses',
        component: () => import('@apps/school/views/Courses'),
        meta: {
            layout: 'content',
            access: {
                members: [ MEMBER_STUDENT, MEMBER_TEACHER ]
            }
        }
    },
    {
        path: '/courses/:uuid',
        name: 'courses.details',
        component: () => import('@apps/school/views/Courses/CourseDetails'),
        redirect: { name: 'courses.docs' },
        meta: {
            layout: 'content',
            access: {
                members: [ MEMBER_STUDENT, MEMBER_TEACHER ]
            }
        },
        children: [
            {
                path: 'docs',
                name: 'courses.docs',
                component: () => import('@apps/school/views/Courses/CourseDocs'),
                props: true,
                meta: {
                    layout: 'content',
                    access: {
                        members: [ MEMBER_STUDENT, MEMBER_TEACHER ]
                    }
                }
            },
            {
                path: 'teachers',
                name: 'courses.teachers',
                component: () => import('@apps/school/views/Courses/CourseTeachers'),
                props: true,
                meta: {
                    layout: 'content',
                    access: {
                        members: [ MEMBER_STUDENT, MEMBER_TEACHER ]
                    }
                }
            },
            // {
            //     path: 'questions',
            //     name: 'courses.questions',
            //     component: () => import('@apps/school/views/Courses/CourseQuestions'),
            //     props: true,
            //     meta: {
            //         layout: 'content',
            //         access: {
            //             members: [ MEMBER_STUDENT, MEMBER_TEACHER ]
            //         }
            //     }
            // },
            {
                path: 'groups',
                name: 'courses.groups',
                component: () => import('@apps/school/views/Courses/CourseGroups'),
                props: true,
                meta: {
                    layout: 'content',
                    access: {
                        members: [ MEMBER_TEACHER ]
                    }
                }
            }

        ]
    }
]
