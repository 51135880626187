import preset from './default-preset/preset'
import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import VuetifyConfirm from './confirm'
import VueI18n from 'vue-i18n'
import { en, ru, uk } from 'vuetify/lib/locale'
import Ripple from 'vuetify/lib/directives/ripple'
import DatetimePicker from 'vuetify-datetime-picker'

Vue.use(Vuetify, {
    directives: {
        Ripple,
    },
})
Vue.use(VueI18n)

const messages = {
    en: {
        $vuetify: en
    },
    ru: {
        $vuetify: ru
    },
    uk: {
        $vuetify: uk
    }
}

const vuetifyI18n = new VueI18n({
    locale: 'en', // set locale
    messages // set locale messages
})

export const setVuetifyI18nLanguage = function (lang) {
    vuetifyI18n.locale = lang

    return lang
}

const vuetify = new Vuetify({
    preset,
    lang: {
        t: (key, ...params) => vuetifyI18n.t(key, params),
    },
    theme: {
        options: {
            customProperties: true
        }
    }
})

// Install confirmation dialog plugin
Vue.use(VuetifyConfirm, { vuetify })
// Install DatetimePicker plugin
Vue.use(DatetimePicker, { vuetify })

export default vuetify
