<template>
    <w-dialog-drawer
        v-model="dialog"
        persistent
        width="480"
        :title="$trans('hint.email_verification.title')"
    >
        <v-card
        >
            <v-card-text class="text-center">
                <v-alert
                    border="left"
                    :color="alert.color"
                    dark
                >
                    {{ alert.text }}
                </v-alert>
                <v-sheet
                    max-width="260"
                    class="mx-auto"
                >
                    <v-otp-input
                        v-model="verificationCode"
                        length="4"
                        hide-spin-buttons
                        @change="onChange"
                        @finish="onFinish"
                    ></v-otp-input>
                </v-sheet>
                <div class="d-flex justify-center flex-column mb-4">
                    <p>{{ helpText }}</p>
                    <div>
                        <v-btn
                            :disabled="!isGetCodeAvailable"
                            :loading="loading"
                            color="primary"
                            outlined
                            @click="onGetCode"
                        >
                            {{ $trans('hint.email_verification.button') }}
                        </v-btn>
                    </div>
                </div>
            </v-card-text>
        </v-card>
    </w-dialog-drawer>
</template>

<script>
import { timer } from '@/utils'
import { checkVerificationCode, getVerificationCode } from '@apps/auth/api'
import Debugger from '@/utils/Debugger'

export default {
    name: 'EmailVerificationDialog',
    computed: {
        alert() {
            const alert = {
                text: this.$trans('hint.email_verification.alert.before'),
                color: 'error lighten-2'
            }

            if(this.isSentCode) {
                alert.text = this.$trans('hint.email_verification.alert.after')
                alert.color = 'success'
            }

            return alert
        },
        helpText() {
            if(this.isGetCodeAvailable) {
                return this.$trans('hint.email_verification.help.before')
            } else {
                return this.$trans('hint.email_verification.help.after') + ' ' + this.timer.timeDisplay
            }
        },
        isGetCodeAvailable() {
            return this.timer.timeout || !this.loading
        }
    },
    data: () => ({
        dialog: false,
        loading: false,
        isSentCode: false,
        getCodeTimer: 0,
        verificationCode: null,
        timer: {
            minutes: 1,
            timeout: true,
            timeDisplay: '00:00'
        }
    }),
    mounted() {
        if(this.$auth.user && !this.$auth.user.email_verified_at) {
            this.dialog = true
        }
    },
    methods: {
        async onGetCode() {
            this.loading = true
            this.isSentCode = false
            this.verificationCode = null

            try {
                this.$notification.disabled()
                await getVerificationCode()

                this.loading = false
                this.isSentCode = true

                timer(this.timer)
            } catch (errors) {
                Debugger.log(errors)
            } finally {
                this.loading = false
            }
        },
        onChange() {

        },
        async onFinish() {
            this.loading = true

            try {
                await checkVerificationCode(this.verificationCode)
                this.$emit('success')
                this.dialog = false
            } catch (error) {
                this.verificationCode = null
                Debugger.log(error)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang=scss>

</style>
