/* eslint-disable */
import request from '@/plugins/request'

const prefix = 'lesson-types'

/**
 * @returns {AxiosPromise}
 */
export const getLessonTypesCollection = () => {
    const options = {
        method: 'GET',
        url: `${prefix}`
    }

    return request(options)
}

/**
 *
 * @param {Object} data
 * @returns {AxiosPromise}
 */
export const storeLessonType = data => {
    const options = {
        method: 'POST',
        url: `${prefix}`,
        data: data
    }

    return request(options)
}

/**
 *
 * @param {String} uuid
 * @param {Object} data
 * @returns {AxiosPromise}
 */
export const updateLessonType = (uuid, data) => {
    const options = {
        method: 'PATCH',
        url: `${prefix}/${uuid}`,
        data: data
    }

    return request(options)
}

/**
 *
 * @param {String} uuid
 * @returns {AxiosPromise}
 */
export const deleteLessonType = uuid => {
    const options = {
        method: 'DELETE',
        url: `${prefix}/${uuid}`
    }

    return request(options)
}
