<template>
    <div>
        <w-simple-form-header
            v-if="title || subtitle || action"
            :title="title"
            :subtitle="subtitle"
            :action="actionSwitcher"
            @edit="showAction = 'close'"
            @close="showAction = 'edit'"
        />
        <w-simple-form-preview
            v-if="showPreview"
            :fields="fields"
            :item="item"
        >
            <template
                v-for="field in fields"
                #[`preview.${field.name}`]
            >
                <slot :name="`preview.${field.name}`" :item="item">
                    {{ item[field.name] }}
                </slot>
            </template>
        </w-simple-form-preview>
        <v-card
            v-show="showForm"
            flat
            color="transparent"
            :disabled="disabled"
        >
            <v-form
                ref="wSimpleForm"
                v-model="isValid"
                lazy-validation
                @submit.prevent="() => {}"
            >
                <template v-for="field in fields">
                    <slot
                        :isEditing="isEditing"
                        :isValid="isValid"
                        :form="$refs.wSimpleForm"
                        :name="`field.${field.name}`"
                        :field="field"
                        :data="formData"
                        :attrs="field.props"
                        :errors="getErrors(field.name)"
                        :on="{ ...field.on, focus: resetErrorMessages, input: () => onInput(field.name, formData[field.name]) }"
                    >
                        <component
                            :is="field.type"
                            :key="field.name"
                            v-model="formData[field.name]"
                            :name="field.name"
                            :error-messages="getErrors(field.name)"
                            v-bind="field.props"
                            v-on="field.on"
                            @focus="resetErrorMessages"
                            @input="onInput(field.name, formData[field.name])"
                            @change="onChange(field.name, formData[field.name])"
                        />
                    </slot>
                </template>
                <slot
                    name="actions"
                    :validate="validate"
                    :isValid="isValid"
                    :data="formData"
                    v-on="{click: onSubmit}"
                >
                    <div
                        v-if="!hideDefaultActions"
                        class="d-flex"
                    >
                        <v-btn
                            v-if="isDeletable"
                            text
                            color="error"
                            @click="onDelete"
                        >
                            {{ $trans(deleteText) }}
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            ref="onSubmitBrn"
                            :disabled="!isValid"
                            color="primary"
                            depressed
                            @click="onSubmit"
                        >
                            {{ action.text }}
                        </v-btn>
                    </div>
                </slot>
            </v-form>
        </v-card>
    </div>
</template>

<script>
import hasWSimpleFormHandlers from '@/widgets/components/WSimpleForm/mixins/hasWSimpleFormHandlers'
import WSimpleFormHeader from '@/widgets/components/WSimpleForm/components/WSimpleFormHeader'
import WSimpleFormPreview from '@/widgets/components/WSimpleForm/components/WSimpleFormPreview'

const ACTION_EDIT = 'edit'
const ACTION_CLOSE = 'close'

export default {
    name: 'WSimpleForm',
    components: {
        WSimpleFormPreview,
        WSimpleFormHeader
    },
    mixins: [hasWSimpleFormHandlers],
    computed: {
        actionSwitcher() {
            return this.throughPreview && this.editable
                ? this.showAction
                : null
        },
        showPreview() {
            return this.throughPreview &&
                this.showAction === ACTION_EDIT
        },
        showForm() {
            return !this.throughPreview ||
                (this.throughPreview && this.showAction === ACTION_CLOSE)
        }
    },
    data() {
        return {
            showAction: ACTION_EDIT
        }
    }
}
</script>

<style lang="scss">

</style>
