import { MEDIA } from '@/widgets/components/WIcon/icons'
import { ROLE_SUPER_ADMIN } from '@apps/auth/config/roles'

export default [
    {
        title: null,
        subtitle: null,
        hint: null,
        name: 'media',
        access: {
            roles: [ ROLE_SUPER_ADMIN ]
        },
        items: [
            {

                icon: MEDIA,
                title: 'Media',
                subtitle: null,
                color: null,
                component: () => import('./views/MediaManage'),
                path: 'languages',
                name: 'languages'
            }
        ]
    }
]
