/* eslint-disable */
import { ROLE_ASSISTANT, ROLE_MANAGER } from '@apps/auth/config/roles'

export default [
	{
		path: '/departments',
		name: 'departments.index',
		component: () => import('@/apps/school/views/Departments/DepartmentsIndex'),
		meta: {
			layout: 'content',
            access: {
			    roles: [ ROLE_ASSISTANT, ROLE_MANAGER ]
            }
		}
	},
    {
        path: '/departments/:uuid/courses/:cuuid',
        name: 'departments.courses.details',
        props: true,
        component: () => import('@/apps/school/views/Departments/DepartmentsCourseDetails'),
        meta: {
            layout: 'content',
            access: {
                roles: [ ROLE_ASSISTANT, ROLE_MANAGER ]
            }
        }
    },
    {
        path: '/departments/:uuid/teachers/:tuuid',
        name: 'departments.teachers.profile',
        component: () => import('@/apps/school/views/Departments/DepartmentsTeacherProfile'),
        props: true,
        meta: {
            layout: 'content',
            access: {
                roles: [ ROLE_ASSISTANT, ROLE_MANAGER ]
            }
        }
    },
	{
		path: '/departments/:uuid',
		name: 'departments.show',
		component: () => import('@/apps/school/views/Departments/DepartmentsShow'),
		redirect: {name:'departments.teachers'},
		meta: {
			layout: 'content',
            access: {
                roles: [ ROLE_ASSISTANT, ROLE_MANAGER ]
            }
		},
		children: [
			{
				path: 'teachers',
				name: 'departments.teachers',
				component: () => import('@/apps/school/views/Departments/DepartmentsTeachers'),
                props: true,
				meta: {
					layout: 'content',
                    access: {
                        roles: [ ROLE_ASSISTANT, ROLE_MANAGER ]
                    }
				}
			},
			{
				path: 'courses',
				name: 'departments.courses',
				component: () => import('@/apps/school/views/Departments/DepartmentsCourses'),
                props: true,
				meta: {
					layout: 'content',
                    access: {
                        roles: [ ROLE_ASSISTANT, ROLE_MANAGER ]
                    }
				}
			},
			{
				path: 'capacity',
				name: 'departments.capacities',
				component: () => import('@/apps/school/views/Departments/DepartmentsCapacity'),
				meta: {
					layout: 'content',
                    access: {
                        roles: [ ROLE_ASSISTANT, ROLE_MANAGER ]
                    }
				},
			}
		]
	}
]
