/* eslint-disable */
import { AuthProvider } from '@apps/auth/utils' // get token from cookie

export default async (to, from, next) => {
    const auth = new AuthProvider()
    const isToken = auth.getToken()
    const loginUri = AuthProvider.loginUri
    const forgotPasswordUri = AuthProvider.forgotPasswordUri
    const resetPasswordUri = AuthProvider.resetPasswordUri
    const whiteList = [loginUri, forgotPasswordUri, resetPasswordUri] // no redirect whitelist
    const isWhiteList = whiteList.indexOf(to.path) !== -1

    // determine whether the user has logged in
    if (isToken || isWhiteList) {
        if (!isWhiteList && !auth.check()) {
            try {
                await auth.fetchAuthUser()

                if (to.meta && to.meta.access && !auth.hasAccess(to.meta.access)) {
                    next('/error-404')
                } else {
                    next()
                }
            } catch (err) {
                await auth.resetToken()

                next(loginUri)
            }
        } else {
            if (to.meta && to.meta.access && !auth.hasAccess(to.meta.access)) {
                next('/error-404')
            } else {
                next()
            }
        }
    } else {
        next(loginUri)
    }
}
