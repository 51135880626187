import request from '@/plugins/request'

const prefixSettings = 'media/settings'
const prefixManage = 'media/manage'
const prefixDocuments = 'media/documents'

/**
 * @return {AxiosPromise}
 */
export const getMediaSettings = () => {
    const options = {
        method: 'GET',
        url: `${prefixSettings}`
    }

    return request(options)
}

/**
 * @return {AxiosPromise}
 */
export const getMediaSettingsKey = (key) => {
    const options = {
        method: 'GET',
        url: `${prefixSettings}/${key}`
    }

    return request(options)
}

/**
 * @return {AxiosPromise}
 */
export const setMediaSettings = (data) => {
    const options = {
        method: 'POST',
        url: `${prefixSettings}`,
        data: data
    }

    return request(options)
}

export const getDocuments = payload => {
    const options = {
        method: 'POST',
        url: `${prefixDocuments}`,
        data: payload
    }

    return request(options)
}

export const uploadUrl = data => {
    const options = {
        method: 'POST',
        url: `${prefixManage}`,
        data: {
            action: 'uploadUrl',
            ...data
        }
    }

    return request(options)
}

/**
 * @return {AxiosPromise}
 */
export const removeFile = (src) => {
    const options = {
        method: 'POST',
        url: `${prefixManage}`,
        data: {
            action: 'removeFile',
            src
        }
    }

    return request(options)
}

export const getFile = (src, $export) => {
    if(!src) return null

    if(src.startsWith('blob:') || src.startsWith('http')) {
        return src
    }

    let url = `${process.env.VUE_APP_MEDIA_STORAGE}/${src}`

    if($export) {
        url += '?export=' + $export
    }

    return url
}

/**
 * @return {AxiosPromise}
 */
export const getFileInfo = (src) => {
    const options = {
        method: 'POST',
        url: `${prefixManage}`,
        data: {
            action: 'getFileInfo',
            src
        }
    }

    return request(options)
}

export const uploadFile = ({ file, parent, attachment }) => {
    const form = new FormData()
    form.append('file', file, file.name)
    form.append('action', 'uploadFile')

    form.append('attachment', attachment ? '1' : '0')

    if(parent) {
        form.append('parent', parent)
    }

    const options = {
        method: 'POST',
        url: `${prefixManage}`,
        headers: {
            'Content-Type': 'multipart/form-data; boundary=cclient'
        },
        data: form
    }

    return request(options)
}

export const uploadGoogleDrive = data => {
    const options = {
        method: 'POST',
        url: `${prefixManage}`,
        data: {
            action: 'uploadGoogleDrive',
            ...data
        }
    }

    return request(options)
}

export const shareDocuments = data => {
    const options = {
        method: 'POST',
        url: `${prefixManage}`,
        data: {
            action: 'shareDocuments',
            shared: data
        }
    }

    return request(options)
}

export const detachSharedDocument = data => {
    const options = {
        method: 'POST',
        url: `${prefixManage}`,
        data: {
            action: 'detachSharedDocument',
            ...data
        }
    }

    return request(options)
}

export const updateDocument = data => {
    const options = {
        method: 'POST',
        url: `${prefixManage}`,
        data: {
            action: 'updateDocument',
            ...data
        }
    }

    return request(options)
}

export const removeDocument = data => {
    const options = {
        method: 'POST',
        url: `${prefixManage}`,
        data: {
            action: 'removeDocument',
            ...data
        }
    }

    return request(options)
}

export const restoreDocument = data => {
    const options = {
        method: 'POST',
        url: `${prefixManage}`,
        data: {
            action: 'restoreDocument',
            ...data
        }
    }

    return request(options)
}

export const deleteDocument = data => {
    const options = {
        method: 'POST',
        url: `${prefixManage}`,
        data: {
            action: 'deleteDocument',
            ...data
        }
    }

    return request(options)
}

export const cleanUpDocuments = data => {
    const options = {
        method: 'POST',
        url: `${prefixManage}`,
        data: {
            action: 'cleanUpDocuments',
            ...data
        }
    }

    return request(options)
}

export const createFolder = data => {
    const options = {
        method: 'POST',
        url: `${prefixManage}`,
        data: {
            action: 'createFolder',
            ...data
        }
    }

    return request(options)
}
