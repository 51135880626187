<template>
    <component :is="resolveLayoutVariant" id="layout-content">
        <v-progress-linear
            :active="$lprogress.isLoading()"
            height="2"
            indeterminate
            absolute
            color="primary"
        />
        <transition
            :name="appRouteTransition"
            mode="out-in"
            appear
        >
            <v-card
                :disabled="$lprogress.isLoading()"
                tile
                flat
                color="transparent"
            >
                <router-view></router-view>
            </v-card>
        </transition>
        <email-verification-dialog/>
    </component>
</template>

<script>
import themeConfig from '@themeConfig'
import { getToken } from '@/apps/auth/utils'
import appsBootstrap from '@/plugins/appsBootstrap'
import EmailVerificationDialog from '@apps/auth/components/EmailVerificationDialog'

export default {
    name: 'App',
    components: {
        EmailVerificationDialog,
        'layout-content': () => import('@/layouts/Layout/LayoutContent'),
        'layout-blank': () => import('@/layouts/Layout/LayoutBlank')
    },
    data: () => ({
        appRouteTransition: themeConfig.app.routeTransition
    }),
    computed: {
        resolveLayoutVariant() {
            if (this.$route.meta.layout === 'blank') return 'layout-blank'
            if (this.$route.meta.layout === 'content') return 'layout-content'

            return null
        }
    },
    mounted() {
        this.boot()
    },
    methods: {
        async boot() {
            // console.log(getToken())
            if (getToken()) {
                await appsBootstrap.boot(this)
            }
        }
    }
}
</script>
