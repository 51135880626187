export const SCHOOL = 'mdi-folder-table'
export const LEVEL = 'mdi-package-variant-closed'
export const DEPARTMENT = 'mdi-collage'
export const GROUP = 'mdi-account-box-multiple'
export const STUDENT = 'mdi-account'
export const TEACHER = 'mdi-school'
export const SCHEDULE = 'mdi-calendar-clock-outline'
export const NEXT = 'mdi-chevron-right'
export const NEXT_DOUBLE = 'mdi-chevron-double-right'
export const PREV = 'mdi-chevron-left'
export const PREV_DOUBLE = 'mdi-chevron-double-left'
export const UP = 'mdi-chevron-up'
export const DOWN = 'mdi-chevron-down'
export const DROP_DOWN = 'mdi-menu-down'
export const DROP_UP = 'mdi-menu-up'
export const COM_BACK = 'mdi-arrow-left'
export const ADD = 'mdi-plus'
export const CLOSE = 'mdi-close'
export const CONFIG = 'mdi-cog'
export const CONFIG_OUTLINE = 'mdi-cog-outline'
export const SETTINGS = 'mdi-tune'
export const MANAGE = 'mdi-widgets'
export const COURSE = 'mdi-book-open-page-variant'
export const LOCATION = 'mdi-map-marker'
export const CALENDAR = 'mdi-calendar'
export const CALENDAR_CLOCK = 'mdi-calendar-clock-outline'
export const CLOCK = 'mdi-clock-outline'
export const CLOCK_FAST = 'mdi-clock-fast'
export const EDIT = 'mdi-pencil'
export const VIEW = 'mdi-eye'
export const LESSON = 'mdi-pencil-ruler'
export const CAPACITY = 'mdi-layers-triple-outline'
export const PERIOD = 'mdi-calendar-multiple-check'
export const ALERT = 'mdi-alert'
export const DRAGGABLE = 'mdi-drag'
export const UPDATE = 'mdi-update'
export const DELETE = 'mdi-delete'
export const REFRESH = 'mdi-refresh'
export const DAYBOOK = 'mdi-text-box-check-outline'
export const EMPLOYEE = 'mdi-account-box'
export const TRANSLATE = 'mdi-translate'
export const PERMISSIONS = 'mdi-account-group'
export const ONLINE = 'mdi-access-point'
export const MEDIA = 'mdi-play-box-multiple'
export const DOCUMENT = 'mdi-file-document'
export const DOCUMENTS = 'mdi-file-document-multiple-outline'
export const MENU = 'mdi-menu'
export const OPEN_IN_NEW = 'mdi-open-in-new'
export const DOWNLOAD = 'mdi-download'
export const UPLOAD = 'mdi-upload'
export const LINK = 'mdi-link'
export const INFO = 'mdi-information-outline'
export const FOLDER = 'mdi-folder'
export const FOLDER_PLUS = 'mdi-folder-plus'
export const FOLDER_OPEN = 'mdi-folder-open'
export const CHECK = 'mdi-check'
export const CHECK_ALL = 'mdi-check-all'
export const CHECK_BOLD = 'mdi-check-bold'
export const ERROR = 'mdi-close-circle'
export const YOUTUBE = 'mdi-youtube'
export const AUDIO = 'mdi-file-music-outline'
export const IMAGE = 'mdi-file-image-outline'
export const TRASH = 'mdi-trash-can'
export const GOOGLE_DRIVE = 'mdi-google-drive'
export const RESTORE = 'mdi-restore'
export const CLEAN_UP = 'mdi-delete-forever'
export const SEARCH = 'mdi-magnify'
export const USER = 'mdi-account'
export const SHARE = 'mdi-share-variant'
export const QUESTIONS = 'mdi-clipboard-check-outline'
export const MOVE = 'mdi-share'
export const COPY = 'mdi-content-copy'
export const MOTION = 'mdi-motion-play'
export const CIRCLE = 'mdi-checkbox-blank-circle'
export const HISTORY = 'mdi-history'
export const PASSWORD = 'mdi-form-textbox-password'
export const SEND = 'mdi-send'

export default {
    SCHOOL,
    LEVEL,
    DEPARTMENT,
    GROUP,
    STUDENT,
    TEACHER,
    SCHEDULE,
    NEXT,
    NEXT_DOUBLE,
    PREV,
    PREV_DOUBLE,
    UP,
    DOWN,
    DROP_DOWN,
    DROP_UP,
    COM_BACK,
    ADD,
    CLOSE,
    CONFIG,
    CONFIG_OUTLINE,
    SETTINGS,
    MANAGE,
    COURSE,
    LOCATION,
    CALENDAR,
    CALENDAR_CLOCK,
    CLOCK,
    CLOCK_FAST,
    EDIT,
    VIEW,
    LESSON,
    CAPACITY,
    PERIOD,
    ALERT,
    DRAGGABLE,
    UPDATE,
    DELETE,
    REFRESH,
    DAYBOOK,
    EMPLOYEE,
    TRANSLATE,
    PERMISSIONS,
    ONLINE,
    MEDIA,
    DOCUMENTS,
    DOCUMENT,
    MENU,
    OPEN_IN_NEW,
    DOWNLOAD,
    UPLOAD,
    LINK,
    INFO,
    FOLDER,
    FOLDER_PLUS,
    FOLDER_OPEN,
    CHECK,
    CHECK_ALL,
    CHECK_BOLD,
    ERROR,
    YOUTUBE,
    AUDIO,
    IMAGE,
    TRASH,
    GOOGLE_DRIVE,
    RESTORE,
    CLEAN_UP,
    SEARCH,
    USER,
    SHARE,
    QUESTIONS,
    MOVE,
    COPY,
    MOTION,
    CIRCLE,
    HISTORY,
    PASSWORD,
    SEND
}
