/* eslint-disable */
export default [
    {
        path: '/daybook/index',
        name: 'daybook.index',
        component: () => import('@/apps/school/views/DayBook/DayBookIndex'),
        meta: {
            layout: 'content'
        }
    },
    {
        path: '/daybook/form',
        name: 'daybook.form',
        component: () => import('@/apps/school/views/DayBook/DayBookForm'),
        props: true,
        meta: {
            layout: 'content'
        }
    }
]
