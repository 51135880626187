/* eslint-disable */
import request from '@/plugins/request'

const prefixAuth = '/auth'
const prefixMember = '/member'

export function loginView() {
    return request({
        url: `${prefixAuth}/login-view`,
        method: 'get'
    })
}

export function login(data) {
  return request({
    url: `${prefixAuth}/login`,
    method: 'post',
    data
  })
}

export function fetchAuthUser() {
  return request({
    url: `${prefixAuth}/user`,
    method: 'get'
  })
}

export function updateAuthSettings(data) {
    return request({
        url: `${prefixAuth}/settings`,
        method: 'post',
        data: data
    })
}

export function changeAuthPassword(data) {
    return request({
        url: `${prefixAuth}/change-password`,
        method: 'post',
        data: data
    })
}

export function forgotPassword(data) {
    return request({
        url: `${prefixAuth}/forgot-password`,
        method: 'post',
        data: data
    })
}

export function resetPassword(data) {
    return request({
        url: `${prefixAuth}/reset-password`,
        method: 'post',
        data: data
    })
}

export function logout() {
  return request({
    url: `${prefixAuth}/logout`,
    method: 'post'
  })
}

export function updateMember(member, uuid, data) {
    return request({
        url: `${prefixMember}/${member}/${uuid}`,
        method: 'patch',
        data
    })
}

export function getVerificationCode() {
    return request({
        url: `${prefixAuth}/get-verification-code`,
        method: 'get'
    })
}

export function checkVerificationCode(code) {
    return request({
        url: `${prefixAuth}/check-verification-code`,
        method: 'post',
        data: { code }
    })
}
