import Vue from 'vue'
import Vuex from 'vuex'

import theme from './theme'
import app from './app'
import appsStores from '@/plugins/appsStores'
import getters from './getters'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        theme,
        app,
        ...appsStores
    },
    getters
})
