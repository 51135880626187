import Vue from 'vue'
import VueRouter from 'vue-router'
import AuthGuard from '@apps/auth/utils/auth-guard'
import appsRoutes from '@/plugins/appsRoutes'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/Home.vue'),
        meta: {
            layout: 'content'
        }
    },
    ...appsRoutes,
    {
        path: '/error-404',
        name: 'error-404',
        component: () => import('@/views/Error404'),
        meta: {
            layout: 'blank'
        }
    },
    {
        path: '*',
        redirect: 'error-404'
    }
]

const createRouter = () => {
    const routerInstance = new VueRouter({
        mode: 'history',
        base: process.env.BASE_URL,
        routes,
        scrollBehavior() {
            return {
                x: 0,
                y: 0
            }
        }
    })

    routerInstance.beforeEach(AuthGuard)

    return routerInstance
}

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // reset router
}

export default router
