/* eslint-disable */
import Vue from 'vue'
import { getLanguages, getLocalisation, getSystemLocale, getSystemSettings } from '@apps/system/api'

export default {
    namespaced: true,
    state: {
        locale: 'en',
        localisation: {},
        languages: [],
        enabledLocales: [],
        firstDayOfWeek: 1,
    },
    getters: {
        locale: state => state.locale,
        languages: state => state.languages,
        localisation: state => state.localisation,
        enabledLocales: state => state.enabledLocales,
        firstDayOfWeek: state => state.firstDayOfWeek
    },
    mutations: {
        SET_SETTINGS(state, values) {
            for (const valuesKey in values) {
                if(state[valuesKey]) {
                    state[valuesKey] = values[valuesKey]
                }
            }
        },
        SET_LOCALISATION(state, values) {
            Vue.set(state, 'localisation', values)
        },
        SET_LANGUAGES(state, values) {
            Vue.set(state, 'languages', values)
        },
        SET_LOCALE(state, value) {
            Vue.set(state, 'locale', value)
        },

    },
    actions: {
        async fetchSettings({ commit }) {
            await getSystemSettings()
                .then(response => {
                    const values = response.data.data
                    commit('SET_SETTINGS', values)
                })
        },
        async fetchLocalisation({ commit }, locale) {
            await getLocalisation(locale)
                .then(response => {
                    const items = response.data.data
                    commit('SET_LOCALISATION', items)
                })
        },
        async fetchLanguages({ commit }) {
            await getLanguages()
                .then(response => {
                    const items = response.data.data
                    commit('SET_LANGUAGES', items)
                })
        },
    }
}
